import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import Header from '../../../components/organisms/header'
import Title from '../../../components/atoms/text/title/title'
import OfferForm from '../components/offerForm'
import * as actions from '../duck/actions'
import * as clientActions from '../../clients/duck/actions'
import { getTotalPrice, getUniqueClients, isOfferValid } from '../duck/selectors'
import OfferConfirmation from '../components/offerConfirmation'

class OfferDetailScene extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedClients: [],
            pricePerPerson: 0,
            totalPrice: 0,
            isReadyForSending: false,
            messageBody: `Hello [firstName],\n\nAttached are selected private jet options for review, please note that all aircraft are subject to availability at time of booking. When you open page for signing quote, please upload images of your ID, front and back. If you accept offer by opening link in this email, and authorize with link form confirmation email, your reservation will be held. \n\nPlease let me know if there is anything else that I can do for you.\n\n\nBest, [brokerFirstName]\n\n[brokerSignature]`
        }
    }

    componentDidMount() {
        const { loadClients, loadOffer, match } = this.props
        loadClients()
        const { id } = match.params
        if (id !== 'new') {
            loadOffer(id)
        }
    }

    onAddClient = (client) => {
        const { selectedClients, pricePerPerson } = this.state
        const { activeOrder } = this.props
        const selClients = [...getUniqueClients(selectedClients, client), client]
        this.setState({
            selectedClients: selClients,
            totalPrice: getTotalPrice(activeOrder, pricePerPerson)
        })
    }

    onRemoveClient = (client) => {
        const { activeOrder } = this.props
        this.setState(({ selectedClients, pricePerPerson }) => ({
            selectedClients: [...getUniqueClients(selectedClients, client)],
            totalPrice: getTotalPrice(activeOrder, pricePerPerson)
        }))
    }

    onCancel = () => {
        const { history } = this.props
        history.push('/offers')
    }

    onSend = () => {
        const { isReadyForSending, selectedClients, pricePerPerson } = this.state
        const { activeOrder } = this.props
        if (!isOfferValid(activeOrder, selectedClients, pricePerPerson)) {
            return
        }
        if (isReadyForSending) {
            const { storeOffer, match, history } = this.props
            const { messageBody } = this.state
            const { id } = match.params
            storeOffer({
                id,
                order: activeOrder,
                clients: selectedClients,
                history,
                offerText: messageBody,
                pricePerPerson
            })
            this.setState({ isReadyForSending: false })
            return
        }
        this.setState({ isReadyForSending: true })
    }

    onChangePrice = (key, value) => {
        const { activeOrder } = this.props
        this.setState({
            pricePerPerson: value,
            totalPrice: getTotalPrice(activeOrder, value)
        })
    }

    render() {
        const { match, activeOrder, allClients, order = {}, offer } = this.props
        const {
            selectedClients,
            pricePerPerson,
            totalPrice,
            isReadyForSending,
            messageBody
        } = this.state
        const { id } = match.params
        const title = id === 'new' ? 'New offer' : 'Offer details'
        return (
            <div className={'orders-page'}>
                {isReadyForSending && (
                    <OfferConfirmation
                        order={order}
                        onClose={() => this.setState({ isReadyForSending: false })}
                        messageBody={messageBody}
                        onSubmit={this.onSend}
                    />
                )}
                <Header activePage={'Orders'} pageTitle={title} />
                <div className={'container offer-details-container'}>
                    <Title title={title} backLink={'/offers'} />
                    <div>
                        {activeOrder && (
                            <OfferForm
                                order={activeOrder}
                                clients={allClients || []}
                                selectedClients={selectedClients}
                                onAddClient={this.onAddClient}
                                onRemoveClient={this.onRemoveClient}
                                onCancel={this.onCancel}
                                onSubmit={this.onSend}
                                isNew={id === 'new'}
                                onChangePrice={this.onChangePrice}
                                pricePerPerson={pricePerPerson}
                                totalPrice={totalPrice}
                            />
                        )}
                        {offer && (
                            <OfferForm
                                order={{ ...offer.order, offer }}
                                clients={allClients || []}
                                selectedClients={selectedClients}
                                onAddClient={this.onAddClient}
                                onRemoveClient={this.onRemoveClient}
                                onCancel={this.onCancel}
                                onSubmit={this.onSend}
                                isNew={id === 'new'}
                                onChangePrice={this.onChangePrice}
                                pricePerPerson={offer.pricePerPerson}
                                totalPrice={totalPrice}
                            />
                        )}
                        {offer === null && activeOrder === null && (
                            <div className={'missing-order'}>
                                <h2>{'Missing or expired flight offer'}</h2>
                                <p>
                                    {'There is no order selected.'}
                                    <br />
                                    {'Go back to list of orders to select one'}
                                    <br />
                                    <Link to={'/orders'}>{'Orders list'}</Link>
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ orders, client }) => {
    return { ...orders, allClients: client.clients }
}

export default connect(mapStateToProps, {
    ...actions,
    ...clientActions
})(withRouter(OfferDetailScene))
