export const currentDate = () => {
    return new Date()
}

export const minDate = (days) => {
    const result = currentDate()
    result.setDate(result.getDate() + days)
    return result
}

export const dateAddHours = (date, hours) => {
    const result = typeof date === 'string' ? new Date(date) : date
    result.setTime(result.getTime() + hours * 60 * 60 * 1000)
    return result
}
