import { API_PROFILE_REQUEST_SUCCESS } from '../../auth/duck/authTypes'
import { UPDATE_PROFILE_FORM } from './dashTypes'

export const initialState = {
    profile: null
}

const reducer = (state = initialState, action) => {
    switch (action.type && action.type) {
        case API_PROFILE_REQUEST_SUCCESS:
            return { ...state, profile: { ...action.payload } }
        case UPDATE_PROFILE_FORM:
            return { ...state, ...action.payload }

        default:
            return state
    }
}

export default reducer
