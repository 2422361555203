export const API_LOAD_ORDERS_REQUEST = 'API_LOAD_ORDERS_REQUEST'
export const API_SEARCH_ORDERS_REQUEST = 'API_SEARCH_ORDERS_REQUEST'
export const API_LOAD_ORDERS_REQUEST_SUCCESS = 'API_LOAD_ORDERS_REQUEST_SUCCESS'
export const API_LOAD_ORDERS_REQUEST_ERROR = 'API_LOAD_ORDERS_REQUEST_ERROR'

export const API_LOAD_OFFERS_REQUEST = 'API_LOAD_OFFERS_REQUEST'
export const API_LOAD_OFFERS_REQUEST_SUCCESS = 'API_LOAD_OFFERS_REQUEST_SUCCESS'
export const API_LOAD_OFFERS_REQUEST_ERROR = 'API_LOAD_OFFERS_REQUEST_ERROR'

export const SET_ACTIVE_ORDER = 'SET_ACTIVE_ORDER'
export const SET_ACTIVE_ORDER_CLIENT = 'SET_ACTIVE_ORDER_CLIENT'

export const API_UPDATE_ORDER_REQUEST = 'API_UPDATE_ORDER_REQUEST'
export const API_UPDATE_ORDER_REQUEST_SUCCESS = 'API_UPDATE_ORDER_REQUEST_SUCCESS'
export const API_UPDATE_ORDER_REQUEST_ERROR = 'API_UPDATE_ORDER_REQUEST_ERROR'

export const API_DELETE_ORDER_REQUEST = 'API_DELETE_ORDER_REQUEST'
export const API_LOAD_ORDER_DETAILS_REQUEST = 'API_LOAD_ORDER_DETAILS_REQUEST'
export const API_LOAD_ORDER_DETAILS_REQUEST_SUCCESS = 'API_LOAD_ORDER_DETAILS_REQUEST_SUCCESS'

export const API_STORE_OFFER_REQUEST = 'API_STORE_OFFER_REQUEST'
export const API_STORE_OFFER_REQUEST_SUCCESS = 'API_STORE_OFFER_REQUEST_SUCCESS'
export const API_STORE_OFFER_REQUEST_ERROR = 'API_STORE_OFFER_REQUEST_ERROR'

export const API_GET_OFFER_REQUEST = 'API_GET_OFFER_REQUEST'
export const API_GET_OFFER_REQUEST_SUCCESS = 'API_GET_OFFER_REQUEST_SUCCESS'
export const API_GET_OFFER_REQUEST_ERROR = 'API_GET_OFFER_REQUEST_ERROR'
