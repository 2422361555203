import { toast } from 'react-toastify'
import { BASE_URL, FLY_API, FLY_TOKEN } from '../config'
import { postRequest } from '../requests'
import {
    API_FLY_EMPTY_INSTANT_QUOTE,
    API_FLY_EMPTY_INSTANT_QUOTE_SUCCESS,
    API_FLY_EMPTY_LEGS_SEARCH,
    API_FLY_INQUIRY_REQUEST,
    FLIGHT_BOOK_REQUEST,
    FLIGHT_BOOK_REQUEST_SUCCESS,
    UPDATE_ORDER_STATUS
} from '../../../scenes/destinations/duck/types'
import { authHeaders } from '../commonApi'

const FlightSearchApiMiddleware = ({ state, dispatch }) => (next) => async (action) => {
    switch (action.type) {
        case API_FLY_EMPTY_LEGS_SEARCH:
            try {
                const url = `${FLY_API}emptylegs/search`
                const result = await postRequest({
                    url,
                    state,
                    body: {
                        ...action.payload,
                        paxNum: action.payload.numberOfSeats,
                        apiKey: FLY_TOKEN
                    }
                })
                const { flights } = result
                if (flights) {
                    const { onSuccess } = action.payload
                    if (onSuccess) {
                        // onSu/ccess(flights.map((flight) => FlightParser.parseEasyFlyFLight(flight)))
                    }
                }
            } catch (error) {
                toast(error.message)
            }

            break

        case API_FLY_EMPTY_INSTANT_QUOTE:
            try {
                const body = {
                    legs: action.payload.filters,
                    apiKey: FLY_TOKEN,
                    paxNum: action.payload.numberOfSeats
                }
                const url = `${FLY_API}fleet/instantquote`
                const result = await postRequest({
                    url,
                    state,
                    body
                })
                const { quotes } = result
                const { activeFilter } = action.payload
                if (quotes) {
                    dispatch({
                        type: API_FLY_EMPTY_INSTANT_QUOTE_SUCCESS,
                        payload: quotes.map((quote) => {
                            const { legs } = quote
                            if (activeFilter === 'One way') {
                                const newQuote = { ...quote }
                                newQuote.legs = legs.filter(
                                    (leg) => leg.airportFrom._id === body.legs[0].fromICAO
                                )
                                return { ...newQuote, id: quote._id }
                            }
                            return { ...quote, id: quote._id }
                        })
                    })
                }
            } catch (error) {
                toast(error.message, { type: 'error' })
            }

            break

        case API_FLY_INQUIRY_REQUEST:
            try {
                // const { flight, user } = action.payload
                // const url = `${BASE_URL}order/add` // `${FLY_API}fleet/inquire`
                // const body = {
                //     customerName: `${user.firstName} ${user.lastName}`,
                //     customerEmail: user.email,
                //     customerPhone: user.phoneNumber,
                //     pax: flight.seats,
                //     quoteId: flight.id,
                //     apiKey: FLY_TOKEN,
                //     quote: flight
                // }

                // const response = await postRequest({
                //     url,
                //     state,
                //     isSecure: true,
                //     body
                // })

                // const { referenceId } = response
                // console.log('response inquiry', response)
                // if (referenceId) {
                //     dispatch({
                //         type: API_FLY_STORE_ORDER,
                //         payload: {
                //             ...flight,
                //             referenceId: referenceId || 0,
                //         },
                //     })
                // dispatch({
                //     type: API_ORDERS_CLEAR
                // })
                // dispatch({
                //     type: API_STORE_ORDER,
                //     payload: {
                //         quote: flight,
                //         referenceId,
                //         pax: flight.seats,
                //         quoteId: flight.id,
                //     },
                // })
                const { onSuccess } = action.payload
                if (onSuccess) {
                    onSuccess()
                }
                return
            } catch (error) {
                toast(error.message, { type: 'error' })
            }
            break

        case FLIGHT_BOOK_REQUEST:
            try {
                const result = await postRequest({
                    url: `${BASE_URL}order/add`,
                    headers: authHeaders(),
                    body: action.payload
                })
                const { status, error } = result
                // console.log('booking completed', result)
                if (error) {
                    toast(error.message, { type: 'error' })
                }
                if (status) {
                    dispatch({
                        type: FLIGHT_BOOK_REQUEST_SUCCESS,
                        payload: result
                    })
                    dispatch({
                        type: UPDATE_ORDER_STATUS,
                        payload: { showOrderSuccess: true }
                    })
                }
            } catch (error) {
                toast(error.message, { type: 'error' })
            }
            break
        default:
            break
    }
    next(action)
}

export default FlightSearchApiMiddleware
