import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import { USER_ICON_ACTIVE } from '../../../../../../../../assets/assets'

const ItemUserContainer = () => {
    const users = [
        { id: 1111, name: 'John Doe', image: USER_ICON_ACTIVE, email: 'milosavmatic@yahoo.com' },
        { id: 121, name: 'John Doe', image: USER_ICON_ACTIVE, email: 'milosavmatic@yahoo.com' },
        { id: 131, name: 'John Doe', image: USER_ICON_ACTIVE, email: 'milosavmatic@yahoo.com' },
        { id: 141, name: 'John Doe', image: USER_ICON_ACTIVE, email: 'milosavmatic@yahoo.com' },
        { id: 111, name: 'John Doe', image: USER_ICON_ACTIVE, email: 'milosavmatic@yahoo.com' },
        { id: 1211, name: 'John Doe', image: USER_ICON_ACTIVE, email: 'milosavmatic@yahoo.com' },
        { id: 1311, name: 'John Doe', image: USER_ICON_ACTIVE, email: 'milosavmatic@yahoo.com' },
        { id: 41, name: 'John Doe', image: USER_ICON_ACTIVE, email: 'milosavmatic@yahoo.com' }
    ]

    return (
        <div className={'list-users-items'}>
            {users.map((user) => {
                return (
                    <div className={'item-user-container'} key={user.id}>
                        <img src={user.image} alt={''} />
                        <div>
                            <h2 className={'user-name'}>{user.name}</h2>
                            <p className={'user-email'}>{user.email}</p>
                        </div>
                        <button className={'messages-button'}>
                            <FontAwesomeIcon icon={faComments} />
                        </button>
                    </div>
                )
            })}
        </div>
    )
}

export default ItemUserContainer
