import React from 'react'
import { Link } from 'react-router-dom'
import {
    DESTINATIONS_ICON,
    DESTINATIONS_ICON_ACTIVE,
    FAA_ICON,
    FAA_ICON_ACTIVE,
    HOME_ICON,
    HOME_ICON_ACTIVE,
    MARKETING_ICON,
    MARKETING_ICON_ACTIVE,
    ORDERS_ICON,
    ORDERS_ICON_ACTIVE,
    USER_ICON,
    USER_ICON_ACTIVE,
    WEATHER_ICON,
    WEATHER_ICON_ACTIVE
} from '../../assets/assets'

const NavigationBar = ({ activePage = 'Dashboard' }) => {
    const links = [
        { path: '/dashboard', title: 'Dashboard', icon: HOME_ICON, active: HOME_ICON_ACTIVE },
        {
            path: '/destinations',
            title: 'Aircrafts',
            icon: DESTINATIONS_ICON,
            active: DESTINATIONS_ICON_ACTIVE
        },
        { path: '/clients', title: 'Clients', icon: USER_ICON, active: USER_ICON_ACTIVE },
        { path: '/orders', title: 'Orders', icon: ORDERS_ICON, active: ORDERS_ICON_ACTIVE },
        {
            path: '/weather-map',
            title: 'Weather map',
            icon: WEATHER_ICON,
            active: WEATHER_ICON_ACTIVE
        },
        { path: '/faa', title: 'FAA', icon: FAA_ICON, active: FAA_ICON_ACTIVE },
        {
            path: '/marketing-tool',
            title: 'Marketing tool',
            icon: MARKETING_ICON,
            active: MARKETING_ICON_ACTIVE
        }
    ]

    const isActive = ({ title }) => {
        return activePage === title
    }

    return (
        <div className={'nav-bar'}>
            <div className={'nav-bar-container'}>
                {links.map((linkItem) => (
                    <Link
                        to={linkItem.path}
                        className={`nav-link ${isActive(linkItem) && 'active-link'}`}
                        key={linkItem.path}>
                        <img
                            alt={linkItem.title}
                            src={isActive(linkItem) ? linkItem.active : linkItem.icon}
                        />
                        <span>{linkItem.title}</span>
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default NavigationBar
