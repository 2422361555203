import React from 'react'
import { Link } from 'react-router-dom'
import { BROKER_LOGO, LETTER_ICON_WHITE, MARKETING_ICON_WHITE } from '../../assets/assets'
import NotificationItem from '../atoms/nav/notificationItem'
import SearchField from '../atoms/form/searchField'
import ProfileItem from './profileItem'

const HeaderBar = ({ userProfile = {}, isPublic = false }) => {
    return (
        <div className={'header-bar'}>
            <div className={'container'}>
                <div className={'columns'}>
                    {isPublic && (
                        <p className={'header-logo is-in-center'}>
                            <img src={BROKER_LOGO} alt={'Broker Connect Logo'} />
                        </p>
                    )}
                    {!isPublic && (
                        <div className={'logo-container'}>
                            <Link to={'/dashboard'} className={'header-logo'}>
                                <img src={BROKER_LOGO} alt={'Broker Connect Logo'} />
                            </Link>
                            <div className={'search-bar'}>
                                <SearchField />
                            </div>
                        </div>
                    )}
                    {!isPublic && (
                        <div className={'icons-bar'}>
                            <NotificationItem />
                            <NotificationItem
                                icon={LETTER_ICON_WHITE}
                                //     menu={[{ link: 'google.com', text: 'Google' }]}
                            />
                            <NotificationItem icon={MARKETING_ICON_WHITE} />
                            <div className={'separator'} />
                            <ProfileItem userProfile={userProfile} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default HeaderBar
