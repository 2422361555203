import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faAngleDown, faUserAlt } from '@fortawesome/free-solid-svg-icons'
import Avatar from '../atoms/nav/avatar'
import { logout } from '../../utilities/authUtility'
import { objectFromStorage } from '../../services/helpers/storageHelper'

const ProfileItem = () => {
    const [menuOn, setMenuOn] = useState(false)

    const user = () => {
        const userProfile = objectFromStorage('userProfile')
        return {
            firstName: userProfile ? userProfile.firstName || userProfile.email : '',
            lastName: userProfile && userProfile.lastName ? userProfile.lastName : '',
            image: userProfile && userProfile.photo ? userProfile.photo : null,
            company: userProfile && userProfile.company ? userProfile.company : null
        }
    }
    return (
        <div className={'profileItem'}>
            <Avatar
                firstName={user().firstName}
                lastName={user().lastName}
                image={user().image}
                onClick={logout}
            />
            <button onClick={() => setMenuOn(!menuOn)} className={'profile-menu-button'}>
                {/* {user().image && ( */}
                {/*    <img src={userProfile.image} alt={`${user().firstName} ${user().lastName}`} /> */}
                {/* )} */}
                <FontAwesomeIcon icon={faAngleDown} />
            </button>
            <ul className={`menu-drop-down ${menuOn ? 'menu-drop-down-active' : ''}`}>
                <li className={'profile-cell'}>
                    <h2>{`${user().firstName} ${user().lastName}`}</h2>
                    {user().company && <h2>{user().company}</h2>}
                    <p />
                </li>
                <li>
                    <a href={'/profile'}>
                        <FontAwesomeIcon icon={faUserAlt} style={{ marginRight: 10 }} />
                        {'My profile'}
                    </a>
                </li>
                <li>
                    <a href={'/auth/logout'}>
                        <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: 10 }} />
                        {'Logout'}
                    </a>
                </li>
            </ul>
        </div>
    )
}

export default withRouter(ProfileItem)
