import React from 'react'
import moment from 'moment'
import CurrencyAmount from '../../../components/atoms/text/currencyPrice'
import LegsInfo from '../../order/components/legsInfo'
import InputField from '../../../components/atoms/form/inputField'
import ActionButton from '../../../components/atoms/buttons/actionButton'

const QuoteDetailsForm = ({
    order = {},
    onChange = () => {},
    onSave = () => {},
    onDecline = () => {},
    passenger = {}
}) => {
    const { quote = {}, price, pax } = order
    return (
        <div className={'columns'}>
            <div className={'column'}>
                <h2>{'Quote details'}</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <strong>{'Price'}</strong>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                                <CurrencyAmount
                                    amount={price / pax}
                                    currency={quote.ac && quote.ac.currency}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ paddingTop: '0.5rem' }}>
                                <strong>{'Quote sent at'}</strong>
                            </td>
                            <td style={{ textAlign: 'right', padding: '0 0.5rem 0 0' }}>
                                {moment((order && order.created_at) || '').format('MMM DD YYYY')}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <LegsInfo quote={quote} legs={quote.legs || []} />
            </div>
            <div className={'separator'} />
            <div className={'selected-clients'}>
                <h2 style={{ marginBottom: '2.5rem' }}>{'Passenger details'}</h2>
                <div className={'selected-clients-list fixed-padding'}>
                    <p style={{ paddingBottom: '2rem' }}>
                        {'Please enter details for the passenger'}
                    </p>
                    <div className={'quote-client-form'}>
                        <InputField
                            type={'text'}
                            id={'name'}
                            onChange={onChange}
                            label={'Name'}
                            value={passenger.name || ''}
                            placeholder={'First and last name'}
                        />
                        <InputField
                            type={'text'}
                            label={'Email'}
                            placeholder={'my@email.com'}
                            id={'email'}
                            value={passenger.email || ''}
                            onChange={onChange}
                        />
                        <InputField
                            type={'text'}
                            label={'Phone'}
                            placeholder={'+1 (100)'}
                            id={'phone'}
                            onChange={onChange}
                            value={passenger.phone || ''}
                        />
                    </div>
                </div>
            </div>
            <div className={'separator'} />
            <div className={'selected-clients comment'}>
                <h2>{'Comment'}</h2>
                <div className={'selected-clients-list'}>
                    <div className={'quote-client-form'}>
                        <p>{'Add your question or comment:'}</p>
                        <textarea className={'quote-client-comments'} />
                    </div>
                </div>
                <div className={'button-container-details'}>
                    <ActionButton title={'Send'} onClick={onSave} />
                    <ActionButton
                        className={'button-cancel'}
                        title={'Decline'}
                        onClick={onDecline}
                    />
                </div>
            </div>
        </div>
    )
}

export default QuoteDetailsForm
