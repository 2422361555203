import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTrashAlt,
    faEnvelope,
    faPaperPlane,
    faPencilAlt
} from '@fortawesome/free-solid-svg-icons'
import TabBarTitle from '../../../../../../../components/atoms/buttons/tabBarTitle'
import ItemUserContainer from './components/itemUserContainer'

const ClientsContainer = () => {
    const All = (
        <div>
            <input type={'checkbox'} className={'inputAll'} />
            <label htmlFor={'checkbox'}>{'All'}</label>
        </div>
    )
    return (
        <div className={'clients-container-users'}>
            <div className={'title-style'}>
                <TabBarTitle tabs={[All, 'Group']} activeTab={All} />
                <button className={'button-delete'}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                </button>
            </div>
            <div className={'button-container-for-message'}>
                <button className={'messages-button'}>
                    <FontAwesomeIcon icon={faEnvelope} />
                    {'Received'}
                </button>
                <button className={'messages-button'}>
                    <FontAwesomeIcon icon={faPaperPlane} />
                    {'Sent'}
                </button>
                <button className={'messages-button'}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                    {'Draft'}
                </button>
            </div>
            <ItemUserContainer />
        </div>
    )
}

export default ClientsContainer
