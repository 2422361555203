import {
    API_LOAD_CLIENT_QUOTE_REQUEST_SUCCESS,
    API_LOAD_CLIENT_QUOTE_REQUEST_ERROR,
    QUOTE_STATUS
} from './types'

export const initialState = {
    quote: {},
    loadingStatus: 'loading',
    error: null
}

const reducer = (state = initialState, action) => {
    switch (action.type && action.type) {
        case API_LOAD_CLIENT_QUOTE_REQUEST_SUCCESS:
            return { ...state, quote: { ...action.payload }, loadingStatus: 'completed' }
        case QUOTE_STATUS:
            return { ...state, loadingStatus: action.payload }
        case API_LOAD_CLIENT_QUOTE_REQUEST_ERROR:
            return { ...state, error: action.payload }
        default:
            return state
    }
}

export default reducer
