import React from 'react'

const CurrencyAmount = ({ amount = '0', currency = 'usd', priceClassName, currencyClassName }) => {
    return (
        <p className={`price-amount ${priceClassName}`}>
            {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            }).format(amount)}
            <span className={`price-currency ${currencyClassName}`}> </span>
        </p>
    )
}

export default CurrencyAmount
