// icons
export const HOME_ICON = require('./icons/homeIcon.png')
export const HOME_ICON_ACTIVE = require('./icons/homeIconActive.png')
export const HOME_ICON_WHITE = require('./icons/homeIconWhite.png')
export const USER_ICON_ACTIVE = require('./icons/userIconActive.png')
export const USER_ICON_WHITE = require('./icons/userIconWhite.png')
export const USER_ICON = require('./icons/userIcon.png')
export const ORDERS_ICON_ACTIVE = require('./icons/ordersIconActive.png')
export const ORDERS_ICON_WHITE = require('./icons/ordersIconWhite.png')
export const ORDERS_ICON = require('./icons/ordersIcon.png')
export const DESTINATIONS_ICON_ACTIVE = require('./icons/destinationsIconActive.png')
export const DESTINATIONS_ICON_WHITE = require('./icons/destinationsIconWhite.png')
export const DESTINATIONS_ICON = require('./icons/destinationsIcon.png')
export const FAA_ICON_ACTIVE = require('./icons/faaIconActive.png')
export const FAA_ICON = require('./icons/faaIcon.png')
export const FAA_ICON_WHITE = require('./icons/faaIconWhite.png')
export const WEATHER_ICON_ACTIVE = require('./icons/weatherIconActive.png')
export const WEATHER_ICON_WHITE = require('./icons/weatherIconWhite.png')
export const WEATHER_ICON = require('./icons/weatherIcon.png')

export const LETTER_ICON_WHITE = require('./icons/letterWhite.png')

export const MARKETING_ICON_ACTIVE = require('./icons/marketingIconActive.png')
export const MARKETING_ICON_WHITE = require('./icons/marketingIconWhite.png')
export const MARKETING_ICON = require('./icons/marketingIcon.png')

export const TITLE_BACK = require('./images/h1Back.png')

// images
export const BROKER_LOGO = require('./images/brokerConnectLogo.png')
export const MARKETING_IMG = require('./images/marketingImage.png')
//
// export const CLOUDS = require('./images/clouds.png')
// export const LOGO = require('../assets/images/logo.png')
// export const LOGO_WHITE = require('../assets/images/logoWhite.png')
// export const LOGO_BLACK = require('../assets/images/logo-black.png')
// export const SPLASH = require('../assets/images/splashBack.png')
// export const DASHBOARD = require('../assets/images/dashboardHero.png')
// export const BUTTON_ACTION_RIGHT = require('../assets/images/btnArrow.png')
// export const CLOSE = require('../assets/images/close.png')
// export const ARROW_BACK_GRAY = require('../assets/images/arrowBackGray.png')
// export const ARROW_BACK_WHITE = require('../assets/images/arrowBackWhite.png')
// export const DURATION = require('../assets/images/fromTo1Dark.png')
// export const EXPLORE = require('../assets/images/exploreDestination.png')
// export const MONACO = require('../assets/images/monacoExperience.jpg')
// export const JET_BACK = require('../assets/images/jetBackground.png')
// export const ADD_BUTTON = require('../assets/images/addButton.png')
// export const ARROW_DOWN = require('../assets/images/arrowDownIcon.png')
// export const ARROW_DOWN_GRAY = require('../assets/images/arrowUpIcon.png')
// export const ARROW_UP = require('../assets/images/arrowUpIcon.png')
// export const ARROW_TOP = require('../assets/images/arrowTopIcon.png')
// export const ARROW_RIGHT_INPUT = require('../assets/images/arrowRightInput.png')
// export const ARROW_RIGHT_INPUT_WHITE = require('../assets/images/arrowRightInputWhite.png')
// export const SHARE_ICON = require('../assets/images/shareIcon.png')
// export const SHARE_BACK = require('../assets/images/shareBackground.png')
//
// export const PLANE = require('../assets/images/plane.png')
// // export const PLANE_LOADER = require('../assets/images/planeLoader.png')
// export const PLANE_LOADER = require('../assets/images/planeLoader.png')
// export const NO_RESULTS = require('../assets/images/noResults.png')
//
// // tabBar
// export const TAB_HOME = require('../assets/images/homeIcon.png')
// export const TAB_HOME_ACTIVE = require('../assets/images/homeIconActive.png')
//
// export const TAB_PROFILE = require('../assets/images/profile.png')
// export const TAB_PROFILE_ACTIVE = require('../assets/images/profileIconActive.png')
//
// export const TAB_DEALS = require('../assets/images/dealsIcon.png')
// export const TAB_DEALS_ACTIVE = require('../assets/images/dealsIconActive.png')
//
// export const TAB_SEATS = require('../assets/images/seatsIcon.png')
// export const TAB_SEATS_ACTIVE = require('../assets/images/seatsIconActive.png')
//
// export const TAB_CHARTER = require('../assets/images/jets.png')
// export const TAB_CHARTER_ACTIVE = require('../assets/images/jetsActive.png')
//
// // profile
// export const STAR = require('../assets/images/membershipIcon.png')
// export const SETTINGS = require('../assets/images/settingsIcon.png')
// export const NOTIFICATION = require('../assets/images/notificationIcon.png')
// export const PAYMENT = require('../assets/images/paymentIcon.png')
// export const PROFILE_BACKGROUND = require('../assets/images/profileSky.png')
//
// // nav menu buttons
// export const NAV_MENU = require('../assets/images/hamburgerWhiteIcon.png')
// export const NAV_MENU_DARK = require('../assets/images/hamburger.png')
// export const NAV_CHAT = require('../assets/images/conciergeWhiteIcon.png')
// export const NAV_CHAT_DARK = require('../assets/images/conciergeGray.png')
//
// // picker btns
// export const MINUS_BTN = require('../assets/images/minus.png')
// export const MINUS_ACTIVE_BTN = require('../assets/images/minusActive.png')
// export const PLUS_BTN = require('../assets/images/plus.png')
// export const PLUS_BTN_ACTIVE = require('../assets/images/plusActive.png')
//
// // input icons
// export const ICON_INPUT_DEFAULT = require('../assets/images/whereInputIcon.png')
// export const ICON_INPUT_DEPARTURE = require('../assets/images/departingInputIcon.png')
// export const ICON_INPUT_ARRIVAL = require('../assets/images/planeInputIcon.png')
// export const ICON_INPUT_TIME = require('../assets/images/calendarInputIcon.png')
//
// export const POWERED_BY = require('../assets/images/avinodeRgb.png')
//
// export const CARD_BACKGROUND = require('../assets/images/goldCardBackground.png')
// export const IMG_PLACEHOLDER = require('../assets/images/imagePlaceholder.png')
//
// export const ONBOARDING_NOTIFICATIONS = require('../assets/images/onBoardinNotifications.png')
// export const ONBOARDING_LOCATION = require('../assets/images/onBoardinMyLocation.png')
// export const ONBOARDING_WANT_MORE = require('../assets/images/onBoardinWantMore.png')
// export const ONBOARDING_ON_DEMAND = require('../assets/images/onBoardinOnDemand.png')
//
// export const USER_PROFILE = require('../assets/images/userProfile.png')
//
// export const SIDE_ICON_SETTINGS = require('../assets/images/sideMenuSettings.png')
// export const SIDE_ICON_PAYMENT = require('../assets/images/sideIconPayment.png')
// export const SIDE_ICON_MEMBERSHIP = require('../assets/images/sideIconStar.png')
// export const SIDE_ICON_LOGOUT = require('../assets/images/sideIconLogout.png')
//
// export const TICKET_BACKGROUND = require('../assets/images/ticketBackground.png')
// export const CHECKBOX_SELECT = require('../assets/images/checkbox.png')
//
// export const CHASE_LOGO = require('../assets/images/chasePayLogo.png')
// export const CHASE_BUTTON = require('../assets/images/chaseBtn.png')
// export const PHONE_ICON = require('../assets/images/phoneIcon.png')
//
// export const JET_LOGO = require('../assets/images/jetLogo.png')
