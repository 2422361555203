import React from 'react'
import { Helmet } from 'react-helmet'

const PageTitle = ({ title = '' }) => {
    return (
        <Helmet>
            <meta charSet={'utf-8'} />
            <title>{`${title} - Broker Connect`}</title>
        </Helmet>
    )
}

export default PageTitle
