import { toast } from 'react-toastify'
import { authHeaders, methodType, toJson } from '../commonApi'
import { BASE_URL } from '../config'
import {
    API_LOAD_CLIENT_DETAILS_REQUEST,
    API_LOAD_CLIENT_DETAILS_REQUEST_ERROR,
    API_LOAD_CLIENT_DETAILS_REQUEST_SUCESS,
    API_LOAD_CLIENTS_REQUEST,
    API_LOAD_CLIENTS_REQUEST_ERROR,
    API_LOAD_CLIENTS_REQUEST_SUCCESS,
    API_SEARCH_FIELD_REQUEST,
    DELETE_CLIENT_DETAILS_REQUEST,
    STORE_CLIENT_DETAILS_REQUEST
} from '../../../scenes/clients/duck/types'

const ClientApiMiddleware = ({ dispatch, getState }) => (next) => async (action) => {
    const state = getState()
    const { type = '' } = action
    switch (type) {
        case API_LOAD_CLIENTS_REQUEST:
            // console.log('should load clients')
            try {
                const response = await fetch(`${BASE_URL}broker/list`, {
                    method: methodType.get,
                    headers: authHeaders(state)
                })
                const responseJson = await response.json()
                const { error } = responseJson
                if (error) {
                    dispatch({
                        type: API_LOAD_CLIENTS_REQUEST_ERROR,
                        payload: {
                            errorMessage: error,
                            isLoading: false
                        }
                    })
                    return
                }
                const { clients } = responseJson
                dispatch({
                    type: API_LOAD_CLIENTS_REQUEST_SUCCESS,
                    payload: { clients }
                })
            } catch (error) {
                dispatch({
                    type: API_LOAD_CLIENTS_REQUEST_ERROR,
                    payload: {
                        errorMessage: error,
                        isLoading: false
                    }
                })
            }
            break

        case API_LOAD_CLIENT_DETAILS_REQUEST:
            try {
                const { id } = action.payload
                const response = await fetch(`${BASE_URL}broker/client/details/${id}`, {
                    method: methodType.get,
                    headers: authHeaders(state)
                })
                const responseJson = await response.json()
                const { error } = responseJson
                if (error) {
                    dispatch({
                        type: API_LOAD_CLIENT_DETAILS_REQUEST_ERROR,
                        payload: {
                            errorMessage: error,
                            isLoading: false
                        }
                    })
                    return
                }
                const { client } = responseJson
                dispatch({
                    type: API_LOAD_CLIENT_DETAILS_REQUEST_SUCESS,
                    payload: { client }
                })
            } catch (error) {
                dispatch({
                    type: API_LOAD_CLIENTS_REQUEST_ERROR,
                    payload: {
                        errorMessage: error,
                        isLoading: false
                    }
                })
            }
            break
        case STORE_CLIENT_DETAILS_REQUEST:
            try {
                const { id, clientDetails, history } = action.payload
                const { user } = clientDetails
                user.status = (user.status && user.status.value) || ''
                const response = await fetch(`${BASE_URL}broker/client/store/${id}`, {
                    method: methodType.post,
                    headers: authHeaders(state),
                    body: toJson({
                        ...clientDetails,
                        user
                    })
                })
                const responseJson = await response.json()
                const { errors, message } = responseJson
                if (errors || (message && message !== 'Done') || response.status !== 200) {
                    let errorMessage = errors ? errors.join(' ') : ''
                    if (message) {
                        errorMessage = message
                    }
                    toast(
                        `Error saving client${errorMessage.length > 0 ? `: ${errorMessage}` : ''}`,
                        {
                            type: 'error'
                        }
                    )
                }
                if (response.status === 200) {
                    history.push('/clients')
                }
            } catch (error) {
                toast(`Error saving client`, {
                    type: 'error'
                })
            }
            break

        case DELETE_CLIENT_DETAILS_REQUEST:
            try {
                const { id } = action.payload

                const response = await fetch(`${BASE_URL}broker/client/delete/${id}`, {
                    method: methodType.delete,
                    headers: authHeaders(state)
                })
                const responseJson = await response.json()
                const { errors, message } = responseJson
                if (errors || (message && message !== 'Done') || response.status !== 200) {
                    let errorMessage = errors ? errors.join(' ') : ''
                    if (message) {
                        errorMessage = message
                    }
                    toast(
                        `Error deleting client${
                            errorMessage.length > 0 ? `: ${errorMessage}` : ''
                        }`,
                        {
                            type: 'error'
                        }
                    )
                    return
                }
                if (response.status === 200) {
                    dispatch({
                        type: API_LOAD_CLIENTS_REQUEST,
                        payload: {}
                    })
                }
            } catch (error) {
                toast(`Error saving client`, {
                    type: 'error'
                })
            }
            break

        case API_SEARCH_FIELD_REQUEST:
            try {
                const { value } = action.payload
                if (value.length < 2) {
                    toast(`Please enter search term`, {
                        type: 'warning'
                    })
                    return
                }
                const response = await fetch(`${BASE_URL}broker/client/search?search=${value}`, {
                    method: methodType.get,
                    headers: authHeaders(state)
                })
                const responseJson = await response.json()
                const { error } = responseJson
                if (error) {
                    dispatch({
                        type: API_LOAD_CLIENTS_REQUEST_ERROR,
                        payload: {
                            errorMessage: error,
                            isLoading: false
                        }
                    })
                }
                const { clients } = responseJson

                dispatch({
                    type: API_LOAD_CLIENTS_REQUEST_SUCCESS,
                    payload: { clients }
                })
            } catch (error) {
                toast(`Error searching clients`, {
                    type: 'error'
                })
            }
            break

        default:
            break
    }
    next(action)
}

export default ClientApiMiddleware
