import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Header from '../../components/organisms/header'
import Title from '../../components/atoms/text/title/title'
// import OfferConfirmationForm from './components/OfferConfirmationForm'
import * as actions from './duck/actions'
import QuoteDetailsForm from './components/quoteDetailsForm'

class OfferConfirmationScene extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            passenger: {
                name: '',
                phone: '',
                email: '',
                comment: ''
            }
        }
    }

    componentDidMount() {
        const { loadClientQuote, match } = this.props
        const { id } = match.params
        if (id) {
            loadClientQuote(id)
        }
    }

    onUpdatePassenger = (key, value) => {
        const { passenger } = this.state
        const newPassenger = { ...passenger }
        newPassenger[key] = value
        this.setState({ passenger: newPassenger })
    }

    onSave = () => {
        // console.log('accepted order')
    }

    onDecline = () => {
        // console.log('declined offer')
    }

    render() {
        const { quote, error } = this.props
        const { passenger = {} } = this.state
        return (
            <div className={'offer-confirmation-page'}>
                <Header activePage={'Flight Offer Details'} isPublic />
                <div className={'container offer-confirmation-container'}>
                    <Title title={'Flight Quote Details'} />
                    {quote && !error && (
                        <QuoteDetailsForm
                            passenger={passenger}
                            order={quote}
                            onChange={this.onUpdatePassenger}
                        />
                    )}
                    {(!quote || error) && (
                        <div>
                            <h2>{'Missing flight offer'}</h2>
                            <p>
                                {error || 'This flight order is deleted or missing.'}
                                <br />
                                {'Please contact your broker.'}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ quote }) => {
    return quote
}

export default connect(mapStateToProps, {
    ...actions
})(withRouter(OfferConfirmationScene))
