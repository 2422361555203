import {
    API_LOAD_CLIENT_DETAILS_REQUEST_SUCESS,
    API_LOAD_CLIENTS_REQUEST_SUCCESS,
    UPDATE_CLIENT_DETAILS,
    UPDATE_SEARCH_FIELD
} from './types'

export const initialState = {
    clients: [],
    isLoading: false,
    clientDetails: { user: {} },
    search: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type && action.type) {
        case API_LOAD_CLIENTS_REQUEST_SUCCESS:
            return { ...state, clients: action.payload.clients }
        case API_LOAD_CLIENT_DETAILS_REQUEST_SUCESS:
            return { ...state, clientDetails: action.payload.client }
        case UPDATE_SEARCH_FIELD:
            return { ...state, ...action.payload }
        case UPDATE_CLIENT_DETAILS:
            return {
                ...state,
                clientDetails: { ...action.payload }
            }
        // return { ...state, clientDetails: { ...action.payload } }

        default:
            return state
    }
}

export default reducer
