import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { confirmAlert } from 'react-confirm-alert'
import Header from '../../../components/organisms/header'
import { actions } from '../duck'
import TabBarTitle from '../../../components/atoms/buttons/tabBarTitle'
// import FilterPicker from '../../../components/atoms/filterPicker'
import PageTitle from '../../../components/atoms/text/pageTitle'
import SearchForm from '../../../components/organisms/searchForm'
import ClientsList from './components/clientsList'
import InviteNewClient from './components/inviteClient/InviteNewClient'
import ClientInfo from './components/clientInfo'

// import { activeFilter } from '../../destinations/duck/selectors'

class ClientsScene extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: 'Clients list',
            activeClient: null
        }
    }

    componentDidMount() {
        const { loadClients, searchClients } = this.props
        const search = this.searchField()
        if (search) {
            searchClients(search)
        }
        loadClients()
    }

    searchField = () => {
        const { location } = this.props
        const search = queryString.parse(location.search)
        return search || ''
    }

    onChangeTab = (activeTab) => {
        this.setState({
            activeTab
        })
    }

    onDeleteClient = (id) => {
        confirmAlert({
            title: 'Please confirm to delete client',
            message: `Client will be will deleted. Are you sure?`,
            buttons: [
                {
                    label: 'Yes, delete the client',
                    onClick: () => {
                        const { deleteClient } = this.props
                        deleteClient(id)
                    }
                },
                {
                    label: 'Cancel'
                }
            ]
        })
    }

    showClientInfo() {
        const { activeClient } = this.state
        return (
            activeClient && (
                <ClientInfo
                    client={activeClient}
                    onClose={() => this.setState({ activeClient: null })}
                />
            )
        )
    }

    render() {
        const { clients, updateSearch, search, onSubmitSearch, loadClients } = this.props
        const { activeTab } = this.state
        return (
            <div>
                {this.showClientInfo()}
                <Header activePage={'Clients'} />
                <PageTitle title={'Clients'} />
                <div className={'container container-clients'}>
                    <div className={'tab-container'}>
                        <TabBarTitle
                            tabs={['Clients list']}
                            activeTab={activeTab}
                            onChange={this.onChangeTab}
                        />

                        {activeTab === 'Clients list' && (
                            <div className={'client-search-container'}>
                                <SearchForm
                                    fields={['name or email']}
                                    id={['search']}
                                    onChange={(key, value, index) => {
                                        const keys = { 0: 'search' }
                                        const field = {}
                                        field[keys[index]] = value
                                        updateSearch(field)
                                    }}
                                    onSubmit={onSubmitSearch}
                                    values={[search]}
                                    onClear={(key, index) => {
                                        const keys = { 0: 'search' }
                                        const field = {}
                                        field[keys[index]] = ''
                                        updateSearch(field)
                                        loadClients()
                                    }}
                                />
                                {/* <FilterPicker */}
                                {/*    active={activeFilter} */}
                                {/*    filters={['Active', 'Pending', 'Verified', 'Rejected']} */}
                                {/* /> */}
                            </div>
                        )}
                    </div>
                    <div>
                        {activeTab === 'Clients list' && (
                            <ClientsList
                                clients={clients}
                                onDelete={this.onDeleteClient}
                                onPressClient={(client) => this.setState({ activeClient: client })}
                            />
                        )}
                    </div>
                    <div>{activeTab === 'Invite new client' && <InviteNewClient />}</div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { client } = state
    return client
}

export default connect(mapStateToProps, {
    ...actions
})(withRouter(ClientsScene))
