import { combineReducers } from 'redux'
import AuthReducer from '../scenes/auth/duck'
import ClientReducer from '../scenes/clients/duck'
import DestinationReducer from '../scenes/destinations/duck'
import OrderReducer from '../scenes/order/duck'
import DashboardReducer from '../scenes/dashboard/duck'
import QuoteReducer from '../scenes/offerConfirmation/duck'

export default combineReducers({
    auth: AuthReducer,
    client: ClientReducer,
    destinations: DestinationReducer,
    orders: OrderReducer,
    dashboard: DashboardReducer,
    quote: QuoteReducer
})
