import React from 'react'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InputField from '../../../components/atoms/form/inputField'
import ActionButton from '../../../components/atoms/buttons/actionButton'

const MultiLegSection = ({
    airports = [],
    onSearchFlights = () => null,
    filter = { legs: [] },
    onFilterUpdated = () => null,
    onAddLeg = () => null,
    onSearchForAirports = () => null,
    onResetFlight = () => null
}) => {
    const onUpdateFilter = (key, value, index) => {
        const newState = { ...filter }
        const activeLeg = { ...newState.legs[index] }
        switch (key) {
            case 'numberOfSeats':
                onFilterUpdated('multiLegFilter', { ...filter, numberOfSeats: value })
                break
            case 'fromSearch':
            case 'toSearch':
                if (value.length > 2) {
                    onSearchForAirports(value, key.replace('Search', ''), index)
                }
                break
            case 'returnFromSearch':
                if (value.length > 2) {
                    onSearchForAirports(value, 'from', 1)
                }
                break
            case 'returnToSearch':
                if (value.length > 2) {
                    onSearchForAirports(value, 'to', 1)
                }
                break

            case 'to':
            case 'from':
            case 'date':
            case 'time':
                activeLeg[key] = value
                break

            default:
                break
        }
        const legs = [...newState.legs]
        legs[index] = { ...activeLeg }
        newState.legs = legs

        onFilterUpdated('multiLegFilter', { ...newState })
    }

    const onSubmitSearch = () => {
        onSearchFlights(filter)
    }

    return (
        <div className={'one-way-container'}>
            <div className={'filter-background'}>
                {filter.legs.map((filterItem, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={JSON.stringify(filterItem) + index}>
                        {index === 0 && (
                            <div className={'title-container'}>
                                <h2>{'Search flights'}</h2>
                                <button className={'button-for-reset'} onClick={onResetFlight}>
                                    {'CLEAR SEARCH'}
                                </button>
                            </div>
                        )}
                        <div className={'form-content'}>
                            <div className={'flight-search-box'}>
                                <InputField
                                    type={'select'}
                                    id={'from'}
                                    placeholder={'From where?'}
                                    value={index === 1 ? '' : filter.legs[index].from}
                                    onChange={(key, value) => onUpdateFilter(key, value, index)}
                                    isSingleField
                                    options={airports}
                                    noOptionMessage={() => 'Enter airport name or city.'}
                                />
                                <FontAwesomeIcon icon={faPaperPlane} className={'icon'} />
                                <InputField
                                    type={'select'}
                                    placeholder={'Where to?'}
                                    value={filter.legs[index].to}
                                    id={'to'}
                                    onChange={(key, value) => onUpdateFilter(key, value, index)}
                                    isSingleField
                                    options={airports}
                                    noOptionMessage={() => 'Enter airport name or city.'}
                                />
                            </div>
                            <div className={'separator'} />
                            <div className={'time-date-box'}>
                                <InputField
                                    type={'date'}
                                    id={'date'}
                                    placeholder={'Departure date?'}
                                    value={filter.legs[index].date}
                                    onChange={(key, value) => onUpdateFilter(key, value, index)}
                                    isSingleField
                                />
                                <InputField
                                    type={'time'}
                                    id={'time'}
                                    placeholder={'Pick time'}
                                    value={filter.legs[index].time}
                                    onChange={(key, value) => onUpdateFilter(key, value, index)}
                                    isSingleField
                                />
                            </div>
                            <div className={'separator'} />
                            {index === 0 ? (
                                <InputField
                                    type={'number'}
                                    id={'numberOfSeats'}
                                    placeholder={'Number of seats'}
                                    value={filter.numberOfSeats || 1}
                                    onChange={(key, value) => onUpdateFilter(key, value)}
                                    maxValue={20}
                                    isSingleField
                                />
                            ) : (
                                <InputField type={'hidden'} />
                            )}
                            <div className={'footer-container multi-leg-footer'}>
                                {index === 0 ? (
                                    <ActionButton
                                        title={'Add'}
                                        className={'wired-button'}
                                        onClick={onAddLeg}
                                    />
                                ) : (
                                    <InputField type={'hidden'} />
                                )}
                            </div>
                        </div>
                    </div>
                ))}
                <div className={'form-content padding-footer'}>
                    <div className={'footer-container multi-leg-footer'}>
                        {/* <ActionButton title={'Add'} className={'wired-button'} onClick={onAddLeg} /> */}
                        <ActionButton onClick={onSubmitSearch} title={'Search flights'} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MultiLegSection
