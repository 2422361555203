import React from 'react'
import { connect } from 'react-redux'
// import * as gs from 'query-string'
import QueryString from 'query-string'
import { withRouter } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import Header from '../../../components/organisms/header'
import Title from '../../../components/atoms/text/title/title'
import SearchForm from '../../../components/organisms/searchForm'
import Cards from '../../../components/atoms/card/cards'
import OrderItem from '../components/orderItem'
import TabBarTitle from '../../../components/atoms/buttons/tabBarTitle'
import * as actions from '../duck/actions'
import * as clientActions from '../../clients/duck/actions'

class OrdersScene extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: 'Upcoming',
            activeCard: 'Total'
        }
    }

    componentDidMount() {
        const { loadOrders, loadClients, history, location } = this.props
        const { search } = location
        const { tab } = QueryString.parse(search) || ''

        if (tab === 'Sent quotes') {
            setTimeout(() => {
                history.push(`/offers`)
            }, 100)
            return
        }

        loadClients()
        loadOrders()
    }

    onChangeTab = (tab) => {
        const { history } = this.props

        if (tab === 'Sent offers') {
            this.setState({ activeTab: tab })
            setTimeout(() => {
                history.push(`/offers`)
            }, 100)

            return
        }
        this.setState({ activeTab: tab })
    }

    onUpdateSearch = (key, value) => {
        const { fields, fieldValues } = this.state
        const index = fields.indexOf(key)
        const values = [...fieldValues]
        values[index] = value

        this.setState({ fieldValues: values })
    }

    onHandlerCardInOrders = (activeCard) => {
        this.setState({ activeCard })
    }

    onSetClient = (order, client) => {
        const { onAddClient, updateOrder } = this.props
        const { clients = [] } = order
        onAddClient({
            ...order,
            clients: [
                ...clients.filter((oldClient) => {
                    return oldClient.id !== client.value
                }),
                { id: client.value, name: client.label }
            ]
        })
        updateOrder({
            ...order,
            clients: [
                ...clients.filter((oldClient) => {
                    return oldClient.id !== client.value
                }),
                { id: client.value, name: client.label }
            ]
        })
    }

    onDeleteOrder = (order) => {
        if (!order) {
            return
        }
        const { legs } = order.quote
        const departure = legs[0]

        confirmAlert({
            title: 'Delete order?',
            message: `Confirm deleting order for flight ${departure.airportFrom.icao} - ${departure.airportTo.icao}`,
            buttons: [
                {
                    label: 'Yes, delete the order',
                    onClick: () => {
                        const { onDeleteOrder } = this.props
                        onDeleteOrder(order._id)
                    }
                },
                {
                    label: 'Cancel'
                }
            ]
        })
    }

    onCreateOffer = (order) => {
        const { history, setActiveOrder } = this.props
        setActiveOrder(order)
        setTimeout(() => {
            history.push(`/offer/new`)
        }, 100)
    }

    onViewOrder = (order) => {
        const { history, setActiveOrder } = this.props
        setActiveOrder(order)
        setTimeout(() => {
            history.push(`/offer/new`)
        }, 100)
    }

    render() {
        const { fields, fieldValues, activeCard, activeTab } = this.state
        const { upcoming, previous } = this.props
        return (
            <div className={'orders-page'}>
                {/* {activeOrder && ( */}
                {/*    <AddOrderClient */}
                {/*        order={activeOrder} */}
                {/*        onClose={setActiveOrder} */}
                {/*        allClients={allClients} */}
                {/*        onSetClient={this.onSetClient} */}
                {/*    /> */}
                {/* )} */}
                <Header activePage={'Orders'} />
                <div className={'container'}>
                    <Title title={'Orders'} />
                    <TabBarTitle
                        tabs={['Previous', 'Upcoming', 'Sent quotes']}
                        activeTab={activeTab}
                        onChange={this.onChangeTab}
                    />
                    <SearchForm
                        fields={fields}
                        values={fieldValues}
                        onChange={this.onUpdateSearch}
                        className={'search-form-field-orders'}
                    />
                    <Cards
                        cards={[
                            { title: 'Total', price: '10 000' },
                            { title: 'Active', price: '5000' },
                            { title: 'Finished', price: '5000' },
                            { title: 'Total value', price: 'USD 20 000' }
                        ]}
                        className={'card-orders'}
                        activeCard={activeCard}
                        onChange={this.onHandlerCardInOrders}
                    />
                    <table className={'orders-list'}>
                        <thead>
                            <tr>
                                {[
                                    'Edit',
                                    'Clients',
                                    'Aircraft',
                                    'Flight',
                                    'Type',
                                    'Pax',
                                    'Duration',
                                    'Price',
                                    'Status'
                                ].map((item) => (
                                    <th
                                        key={item}
                                        className={`left-align ${
                                            item === 'Edit' ? 'edit-order-cell' : ''
                                        }`}>
                                        {item}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {activeTab === 'Upcoming' &&
                                upcoming &&
                                upcoming.map((order) => (
                                    <OrderItem
                                        order={order}
                                        key={order._id}
                                        onSetOrder={this.onCreateOffer}
                                        onDelete={this.onDeleteOrder}
                                        onViewOrder={this.onViewOrder}
                                        shouldDisplayOffer
                                    />
                                ))}
                            {activeTab !== 'Upcoming' &&
                                previous &&
                                previous.map((order) => (
                                    <OrderItem
                                        order={order}
                                        key={order._id}
                                        onSetOrder={this.onCreateOffer}
                                        onDelete={this.onDeleteOrder}
                                    />
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ orders, client }) => {
    return { ...orders, allClients: client.clients }
}

export default connect(mapStateToProps, {
    ...actions,
    ...clientActions
})(withRouter(OrdersScene))
