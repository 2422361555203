import React from 'react'
import { Link } from 'react-router-dom'
import Title from '../../../components/atoms/text/title/title'
import InputField from '../../../components/atoms/form/inputField'
import ActionButton from '../../../components/atoms/buttons/actionButton'

const ProfileDetails = ({ onSave = () => null, profile = {}, onUpdateProfile = () => null }) => {
    // const [profileImage, setProfileImage] = useState('')

    // const onUpdateProfileImage = (event) => {
    //     if (event.target.files && event.target.files[0]) {
    //         const img = event.target.files[0]
    //         setProfileImage(URL.createObjectURL(img))
    //     }
    // }
    const user = (profile && profile.user) || {}

    return (
        <div className={'profile-details-container'}>
            <Title title={'My profile'} />
            {/* {profileImage ? ( */}
            {/*    <div className={'container-for-image'}> */}
            {/*        <img src={profileImage} alt={''} className={'image'} /> */}
            {/*    </div> */}
            {/* ) : ( */}
            {/*    <div className={'container-for-image'}> */}
            {/*        /!* eslint-disable-next-line jsx-a11y/label-has-associated-control *!/ */}
            {/*        <label htmlFor={'icon'} className={'icon'}> */}
            {/*            <img src={user.photo || USER_ICON_ACTIVE} alt={user.email} /> */}
            {/*        </label> */}
            {/*        /!* <label htmlFor={'button'}> *!/ */}
            {/*        /!* <p className={'text-button'}>{'Upload Image'}</p> *!/ */}
            {/*        /!* </label> *!/ */}
            {/*        <input */}
            {/*            type={'file'} */}
            {/*            id={'button'} */}
            {/*            className={'input-for-images'} */}
            {/*            onChange={onUpdateProfileImage} */}
            {/*        /> */}
            {/*    </div> */}
            {/* )} */}
            <hr className={'hr'} />
            <div className={'input-container profile-form-set'}>
                <h3>{'Basic information'}</h3>
            </div>
            <div className={'input-container profile-form-set'}>
                <InputField
                    value={user.firstName}
                    id={'firstName'}
                    label={'First name'}
                    onChange={onUpdateProfile}
                    placeholder={'First name'}
                />
                <InputField
                    placeholder={'Last name'}
                    id={'lastName'}
                    value={user.lastName}
                    label={'Last name'}
                    onChange={onUpdateProfile}
                />
                <InputField
                    placeholder={'Birth date'}
                    id={'dob'}
                    // type={'date'}
                    label={'Birth date'}
                    value={user.dob || ''}
                    onChange={onUpdateProfile}
                />
            </div>
            <hr className={'hr'} />
            <div className={'input-container profile-form-set'}>
                <h3>{'Company information'}</h3>
            </div>

            <div className={'input-container profile-form-set'}>
                <InputField
                    placeholder={'Company'}
                    label={'Company'}
                    id={'company'}
                    value={user.company || ''}
                    onChange={onUpdateProfile}
                />
            </div>
            <hr className={'hr'} />
            <div className={'input-container profile-form-set'}>
                <h3>{'Contact information'}</h3>
            </div>
            <div className={'input-container profile-form-set'}>
                <InputField
                    placeholder={'State/location'}
                    label={'State/Location'}
                    id={'state'}
                    value={user.state || ''}
                    onChange={onUpdateProfile}
                />
                <InputField
                    placeholder={'Email'}
                    label={'Email'}
                    id={'email'}
                    value={user.email || ''}
                    onChange={onUpdateProfile}
                />
                <InputField
                    placeholder={'Phone'}
                    id={'phone'}
                    value={user.phone || ''}
                    label={'Phone'}
                    onChange={onUpdateProfile}
                />
            </div>
            <hr className={'hr'} />
            <div className={'bottom-container'}>
                <div className={'button-container'}>
                    <ActionButton
                        title={'Save'}
                        className={'button-save'}
                        isDark={false}
                        onClick={onSave}
                    />
                    <Link to={'/dashboard'}>
                        <ActionButton className={'button-cancel'} title={'Cancel'} isDark={false} />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default ProfileDetails
