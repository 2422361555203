import React from 'react'
import NavigationBar from '../molecules/navigationBar'
import HeaderBar from '../molecules/headerBar'
import PageTitle from '../atoms/text/pageTitle'

const Header = ({ activePage = 'Dashboard', pageTitle = null, isPublic = false }) => {
    return (
        <div className={'header'}>
            <PageTitle title={pageTitle || activePage} />
            <HeaderBar isPublic={isPublic} />
            {!isPublic && <NavigationBar activePage={activePage} />}
        </div>
    )
}

export default Header
