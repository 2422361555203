import lscache from 'lscache'

const CACHE_EXPIRY = 1000 * 14400

export const arrayFromStorage = (key) => {
    const storedArray = lscache.get(key)
    if (storedArray && Array.isArray(storedArray) && storedArray.length > 0) {
        return storedArray
    }
    return null
}

export const arrayToStorage = (key, array) => {
    if (Array.isArray(array)) {
        lscache.set(key, array, CACHE_EXPIRY) // store to localstorage with expiry date
    }
}

export const objectFromStorage = (key) => {
    return lscache.get(key)
}

export const objectToStorage = (key, object) => {
    if (typeof object === 'object') {
        lscache.set(key, object, CACHE_EXPIRY) // store to localstorage with expiry date
    }
    lscache.set(key, object)
}

export const remove = (key) => {
    lscache.remove(key)
}

export const removeFromStorage = (key) => {
    lscache.remove(key)
}

export const removeAll = () => {
    lscache.flush()
}
