export const API_LOAD_CLIENTS_REQUEST = 'API_LOAD_CLIENTS_REQUEST'
export const API_SEARCH_CLIENTS_REQUEST = 'API_SEARCH_CLIENTS_REQUEST'
export const API_LOAD_CLIENTS_REQUEST_SUCCESS = 'API_LOAD_CLIENTS_REQUEST_SUCCESS'
export const API_LOAD_CLIENTS_REQUEST_ERROR = 'API_LOAD_CLIENTS_REQUEST_ERROR'

export const API_LOAD_CLIENT_DETAILS = 'API_LOAD_CLIENT_DETAILS'
export const API_LOAD_CLIENT_DETAILS_SUCCESS = 'API_LOAD_CLIENT_DETAILS_SUCCESS'
export const API_LOAD_CLIENT_DETAILS_ERROR = 'API_LOAD_CLIENT_DETAILS_ERROR'

export const API_LOAD_CLIENT_DETAILS_REQUEST = 'API_LOAD_CLIENT_DETAILS_REQUEST'
export const API_LOAD_CLIENT_DETAILS_REQUEST_SUCESS = 'API_LOAD_CLIENT_DETAILS_REQUEST_SUCESS'
export const API_LOAD_CLIENT_DETAILS_REQUEST_ERROR = 'API_LOAD_CLIENT_DETAILS_REQUEST_ERROR'

export const UPDATE_CLIENT_DETAILS = 'UPDATE_CLIENT_DETAILS'

export const STORE_CLIENT_DETAILS_REQUEST = 'STORE_CLIENT_DETAILS_REQUEST'
export const STORE_CLIENT_DETAILS_REQUEST_SUCCESS = 'STORE_CLIENT_DETAILS_REQUEST_SUCCESS'
export const STORE_CLIENT_DETAILS_REQUEST_ERROR = 'STORE_CLIENT_DETAILS_REQUEST_ERROR'

export const DELETE_CLIENT_DETAILS_REQUEST = 'DELETE_CLIENT_DETAILS_REQUEST'

export const UPDATE_SEARCH_FIELD = 'UPDATE_SEARCH_FIELD'

export const API_SEARCH_FIELD_REQUEST = 'API_SEARCH_FIELD_REQUEST'
export const API_SEARCH_FIELD_REQUEST_SUCCESS = 'API_SEARCH_FIELD_REQUEST_SUCCESS'
export const API_SEARCH_FIELD_REQUEST_ERROR = 'API_SEARCH_FIELD_REQUEST_ERROR'
