import React from 'react'
import Header from '../../components/organisms/header'
import Title from '../../components/atoms/text/title/title'

class WeatherScene extends React.PureComponent {
    render() {
        return (
            <div>
                <Header activePage={'Weather map'} />
                <div className={'container'}>
                    <Title title={'Weather'} />
                </div>
            </div>
        )
    }
}

export default WeatherScene
