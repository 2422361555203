import React from 'react'

const ActionButton = ({ title = 'Title', onClick = () => null, className = '', isDark = true }) => {
    return (
        <button
            // eslint-disable-next-line react/button-has-type
            type={'submit'}
            onClick={onClick}
            className={`primary-button ${isDark && 'dark-button'} ${className}`}>
            {title}
        </button>
    )
}

export default ActionButton
