import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faInfo } from '@fortawesome/free-solid-svg-icons'

const ClientItem = ({ client = {}, onDelete = () => null, onPressClient = () => null }) => {
    const { user } = client
    return (
        <tr className={'client-item'}>
            <td className={'client-edit-container'}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,no-underscore-dangle */}
                <a href={`/client/${client._id}`} title={'Edit client'}>
                    <FontAwesomeIcon icon={faEdit} />
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                    href={'#'}
                    title={'Delete client'}
                    onClick={() => {
                        // eslint-disable-next-line no-underscore-dangle
                        onDelete(client._id)
                    }}>
                    <FontAwesomeIcon icon={faTrash} />
                </a>
            </td>
            <td className={'left-align client-info'}>
                <button onClick={() => onPressClient(client)} title={'Client details'}>
                    <FontAwesomeIcon className={'icon'} icon={faInfo} />
                    {`${user.firstName || ''} ${user.lastName || ''}`}
                </button>
            </td>
            <td className={'left-align'}>
                <p>{user.email}</p>
            </td>
            <td>
                <p>{client.status || 'Active'}</p>
            </td>
            <td>
                <p>{client.membership || 'Standard'}</p>
            </td>
            <td>
                <p>
                    {'USD '}
                    {client.gross || 0}
                </p>
            </td>
            <td>
                <p>
                    {'USD '}
                    {client.gross || 0}
                </p>
            </td>
        </tr>
    )
}

export default ClientItem
