import { defaultHeaders, authHeaders, methodType } from './commonApi'

const getRequest = async (url, state, isSecure = true) => {
    try {
        return await fetch(url, {
            method: methodType.get,
            headers: isSecure ? authHeaders(state) : defaultHeaders()
        }).then((response) => response.json())
    } catch (error) {
        return {}
    }
}

const postRequest = async ({ url, state, body, isSecure = true }) => {
    try {
        return await fetch(url, {
            method: methodType.post,
            headers: isSecure ? authHeaders(state) : defaultHeaders,
            body: JSON.stringify(body)
        }).then((response) => response.json())
    } catch (error) {
        return {}
    }
}

export { getRequest, postRequest }
