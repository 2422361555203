import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ClientBasicDetails from './components/clientBasicDetails'
import Header from '../../../components/organisms/header'
import PageTitle from '../../../components/atoms/text/pageTitle'
import ClientMembership from './components/clientMembership'
import { actions } from '../duck'

class ClientScene extends React.Component {
    componentDidMount() {
        const { loadClientDetails, match } = this.props
        const { id } = match.params
        if (id !== 'new') {
            loadClientDetails(id)
        }
    }

    onCancel = () => {
        const { history } = this.props
        history.push('/clients')
    }

    onSave = () => {
        const { match, clientDetails, saveClient, history } = this.props
        const { id } = match.params
        saveClient({ id, clientDetails, history })
    }

    onUpdateClient = (key, value) => {
        const { updateClientDetails, clientDetails } = this.props
        if (key === 'note') {
            updateClientDetails({ ...clientDetails, note: value })
            return
        }
        const { user } = { ...clientDetails }
        user[key] = value
        updateClientDetails({ ...clientDetails, user: { ...user } })
    }

    render() {
        const { clientDetails } = this.props
        return (
            <div>
                <PageTitle title={'Client details'} />
                <Header activePage={'Clients'} />
                <div className={'container container-new-client'}>
                    <ClientBasicDetails
                        client={clientDetails}
                        onCancel={this.onCancel}
                        onSave={this.onSave}
                        onUpdateClient={this.onUpdateClient}
                    />
                    <ClientMembership client={clientDetails} onUpdate={this.onUpdateClient} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ client }) => {
    return client
}

export default connect(mapStateToProps, {
    ...actions
})(withRouter(ClientScene))
