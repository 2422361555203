import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import CurrencyAmount from '../../../components/atoms/text/currencyPrice'
import StatusInfo from '../../../components/atoms/text/statusInfo'
import { flightDepartureDateTime, flightDestinations } from '../../../utilities/flightUtitlity'

const OfferItem = ({ offer = {} }) => {
    const fields = ['edit', 'clients', 'image', 'flight', 'type', 'pax', 'fit', 'price', 'status']

    const renderClients = () => {
        const { clients = [] } = offer
        if (clients.length > 0) {
            return (
                <p>
                    {'Offered to '} <br />
                    <strong>{clients.length}</strong>
                    {' clients.'}
                </p>
            )
        }
        return (
            <div>
                <p className={'missing-info'}>{'No chosen clients.'}</p>
            </div>
        )
    }

    const renderField = (field) => {
        const { order = {}, status } = offer || {}
        const { quote, price = 0 } = order || {}
        const { ac = {} } = quote || {}
        switch (field) {
            case 'edit':
                return (
                    status === 'pending' && (
                        <div className={'edit-order-buttons'}>
                            <a href={`/offer/${offer._id}`} title={'View offer'}>
                                <FontAwesomeIcon icon={faEdit} />
                            </a>
                        </div>
                    )
                )
            case 'clients':
                return renderClients()

            case 'pax':
                return <p style={{ paddingLeft: '1rem' }}>{order.pax || 0}</p>

            case 'type':
                return (
                    <p>
                        {ac.title}
                        <br />
                        {ac.type}
                    </p>
                )
            case 'image': {
                const { images = [] } = ac
                return images.length && images.length > 0 ? (
                    <img src={images[0]} alt={ac.title} />
                ) : (
                    <div />
                )
            }

            case 'price': {
                return <CurrencyAmount amount={price} currency={ac.currency} />
            }
            case 'fit':
                return quote && quote.flightTimeStr ? quote.flightTimeStr : ''
            case 'flight':
                return (
                    <p>
                        <strong>{flightDestinations(quote)}</strong> <br />
                        {flightDepartureDateTime(quote)}
                    </p>
                )
            case 'status':
                return <StatusInfo status={offer.status || 'pending'} />
            default:
                return <span />
        }
    }

    return (
        <tr>
            {fields.map((field) => (
                <td className={`${field}-cell`} key={field}>
                    {renderField(field)}
                </td>
            ))}
        </tr>
    )
}

export default OfferItem
