import { API_LOAD_CLIENT_QUOTE_REQUEST, QUOTE_STATUS } from './types'

export const updateLoadingStatus = (status) => ({
    type: QUOTE_STATUS,
    payload: status
})
export const loadClientQuote = (quoteID) => {
    updateLoadingStatus('pending')
    return {
        type: API_LOAD_CLIENT_QUOTE_REQUEST,
        payload: quoteID
    }
}
