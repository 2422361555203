import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'
import { authHeaders, methodType, toJson } from '../commonApi'
import { BASE_URL } from '../config'
import { API_LOAD_CLIENTS_REQUEST_ERROR } from '../../../scenes/clients/duck/types'
import {
    API_DELETE_ORDER_REQUEST,
    API_GET_OFFER_REQUEST,
    API_GET_OFFER_REQUEST_SUCCESS,
    API_LOAD_OFFERS_REQUEST,
    API_LOAD_OFFERS_REQUEST_SUCCESS,
    API_LOAD_ORDER_DETAILS_REQUEST,
    API_LOAD_ORDER_DETAILS_REQUEST_SUCCESS,
    API_LOAD_ORDERS_REQUEST,
    API_LOAD_ORDERS_REQUEST_SUCCESS,
    API_STORE_OFFER_REQUEST,
    API_STORE_OFFER_REQUEST_SUCCESS,
    API_UPDATE_ORDER_REQUEST
} from '../../../scenes/order/duck/types'
import { shouldLogout } from '../../../utilities/authUtility'
import { CLEAR_FLIGHT_SEARCH } from '../../../scenes/destinations/duck/types'

const OrderApiMiddleware = ({ dispatch }) => (next) => async (action) => {
    const { type = '' } = action
    switch (type) {
        case API_LOAD_ORDERS_REQUEST:
            try {
                const response = await fetch(`${BASE_URL}order/list`, {
                    method: methodType.get,
                    headers: authHeaders()
                })
                const responseJson = await response.json()
                const { upcoming, previous, message } = responseJson
                shouldLogout(responseJson)
                if (!message) {
                    dispatch({
                        type: API_LOAD_ORDERS_REQUEST_SUCCESS,
                        payload: { upcoming, previous }
                    })
                }
            } catch (error) {
                dispatch({
                    type: API_LOAD_CLIENTS_REQUEST_ERROR,
                    payload: {
                        errorMessage: error,
                        isLoading: false
                    }
                })
            }
            break

        case API_UPDATE_ORDER_REQUEST:
            try {
                const order = action.payload
                const response = await fetch(`${BASE_URL}order/update/${order.id}`, {
                    method: methodType.post,
                    headers: authHeaders(),
                    body: toJson(order)
                })
                const { error } = response
                if (error) {
                    toast(error, { type: 'error' })
                }
                dispatch({
                    type: API_LOAD_ORDERS_REQUEST
                })
                dispatch({
                    type: CLEAR_FLIGHT_SEARCH
                })
            } catch (error) {
                toast(error.message, { type: 'error' })
            }
            break

        case API_DELETE_ORDER_REQUEST:
            try {
                const orderID = action.payload
                const response = await fetch(`${BASE_URL}order/delete/${orderID}`, {
                    method: methodType.delete,
                    headers: authHeaders()
                })
                const { error } = response
                if (error) {
                    toast(error, { type: 'error' })
                }
                dispatch({
                    type: API_LOAD_ORDERS_REQUEST
                })
                toast('Order deleted successfully', { type: 'success' })
            } catch (error) {
                toast(error.message, { type: 'error' })
            }
            break

        case API_LOAD_ORDER_DETAILS_REQUEST:
            try {
                const orderID = action.payload
                const response = await fetch(`${BASE_URL}order/${orderID}`, {
                    method: methodType.get,
                    headers: authHeaders()
                })
                const responseJson = await response.json()
                const { error } = responseJson
                if (error) {
                    toast(error, { type: 'error' })
                }
                dispatch({
                    type: API_LOAD_ORDER_DETAILS_REQUEST_SUCCESS,
                    payload: responseJson
                })
            } catch (error) {
                toast(error.message, { type: 'error' })
            }
            break

        case API_LOAD_OFFERS_REQUEST:
            try {
                const response = await fetch(`${BASE_URL}offers`, {
                    method: methodType.get,
                    headers: authHeaders()
                })
                const responseJson = await response.json()
                const { offers, message } = responseJson
                shouldLogout(responseJson)
                if (!message) {
                    dispatch({
                        type: API_LOAD_OFFERS_REQUEST_SUCCESS,
                        payload: [...Object.values(offers)]
                    })
                }
            } catch (error) {
                dispatch({
                    type: API_LOAD_CLIENTS_REQUEST_ERROR,
                    payload: {
                        errorMessage: error,
                        isLoading: false
                    }
                })
            }
            break

        // case API_CREATE_OFFER_REQUEST:
        //     try {
        //         const response = await fetch(`${BASE_URL}offers/list`, {
        //             method: methodType.get,
        //             headers: authHeaders()
        //         })
        //         const responseJson = await response.json()
        //         const { upcoming, previous, message } = responseJson
        //         shouldLogout(responseJson)
        //         if (!message) {
        //             dispatch({
        //                 type: API_LOAD_ORDERS_REQUEST_SUCCESS,
        //                 payload: { upcoming, previous }
        //             })
        //         }
        //     } catch (error) {
        //         dispatch({
        //             type: API_LOAD_CLIENTS_REQUEST_ERROR,
        //             payload: {
        //                 errorMessage: error,
        //                 isLoading: false
        //             }
        //         })
        //     }
        //     break

        case API_GET_OFFER_REQUEST:
            try {
                const { id } = action.payload
                const response = await fetch(`${BASE_URL}offers/${id}`, {
                    method: methodType.get,
                    headers: authHeaders()
                })
                const responseJson = await response.json()
                const { offer, message } = responseJson
                shouldLogout(responseJson)
                if (!message) {
                    dispatch({
                        type: API_GET_OFFER_REQUEST_SUCCESS,
                        payload: offer
                    })
                }
            } catch (error) {
                dispatch({
                    type: API_LOAD_CLIENTS_REQUEST_ERROR,
                    payload: {
                        errorMessage: error,
                        isLoading: false
                    }
                })
            }
            break

        case API_STORE_OFFER_REQUEST:
            try {
                const { id, order, clients, offerText, history, pricePerPerson } = action.payload

                const response = await fetch(`${BASE_URL}offers/update/${id}`, {
                    method: methodType.post,
                    headers: authHeaders(),
                    body: toJson({ order, clients, offerText, pricePerPerson })
                })

                const responseJson = await response.json()
                const { message } = responseJson
                shouldLogout(responseJson)

                if (message === 'Done') {
                    confirmAlert({
                        title: 'Offer is successfully sent',
                        message: `Your sent offers will be listed under Orders > Sent Offers tabs`,
                        buttons: [
                            {
                                label: 'Ok',
                                onClick: () => {
                                    history.push('/orders')
                                }
                            },
                            {
                                label: 'Open quotes',
                                onClick: () => {
                                    history.push('/offers')
                                }
                            }
                        ]
                    })
                }
                dispatch({
                    type: API_STORE_OFFER_REQUEST_SUCCESS,
                    offers: []
                })
                // }
            } catch (error) {
                toast(`Error saving offer`, {
                    type: 'error'
                })
            }
            break

        default:
            break
    }
    next(action)
}

export default OrderApiMiddleware
