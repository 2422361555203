import React from 'react'
import moment from 'moment'
import { USER_ICON_ACTIVE } from '../../../../assets/assets'

const ClientCardContainer = ({ clients = [] }) => {
    const clientName = (user) => {
        let name = ''
        if (user.firstName) {
            name += user.firstName
        }
        if (user.lastName) {
            name += ` ${user.lastName}`
        }
        if (name.length < 1) {
            name = user.email
        }
        return name
    }
    return (
        <div className={'content card-container-users'}>
            <h2 className={'title-container'}>{'CLIENT'}</h2>
            <div className={'title-border-dashboard'} />
            <div className={'clients-container'}>
                {clients.map((client) => {
                    return (
                        // eslint-disable-next-line no-underscore-dangle
                        <div className={'list-item-users'} key={client._id}>
                            <img src={USER_ICON_ACTIVE} alt={'users'} />
                            <div className={'name-container'}>
                                <h2 className={'name-users'}>{clientName(client.user)}</h2>
                                <p className={'description-users'}>
                                    {moment(client.user.created_at).fromNow()}
                                </p>
                            </div>
                            {/* eslint-disable-next-line no-underscore-dangle */}
                            <a href={`/client/${client._id}`}>
                                <button className={'button-item'}>{'View'}</button>
                            </a>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ClientCardContainer
