import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Header from '../../../components/organisms/header'
import PageTitle from '../../../components/atoms/text/pageTitle'
import { actions } from '../duck'
import OrderDetailsForm from '../components/orderDetailsForm'
import Title from '../../../components/atoms/text/title/title'
import AddOrderClient from '../components/addOrderClient'

class OrderDetailsScene extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addClientMode: false
        }
    }

    componentDidMount() {
        const { loadOrder } = this.props
        const { match } = this.props
        const { id } = match.params
        loadOrder(id)
    }

    onCancel = () => {
        const { history } = this.props
        history.push('/orders')
    }

    onSave = () => {
        // const { match, clientDetails, saveClient, history } = this.props
        // const { id } = match.params
        // saveClient({ id, clientDetails, history })
        // console.log('on save', order)
    }

    // onUpdateOrder = (key, value) => {
    //     // const { updateClientDetails, clientDetails } = this.props
    //     // if (key === 'note') {
    //     //     updateClientDetails({ ...clientDetails, note: value })
    //     //     return
    //     // }
    //     // const { user } = { ...clientDetails }
    //     // user[key] = value
    //     // updateClientDetails({ ...clientDetails, user: { ...user } })
    // }

    onActivateClients = (isActive) => {
        this.setState({ addClientMode: isActive })
    }

    render() {
        const { activeOrder, setActiveOrder, allClients } = this.props
        const { addClientMode } = this.state
        return (
            <div>
                {addClientMode && (
                    <AddOrderClient
                        order={activeOrder}
                        onClose={setActiveOrder}
                        allClients={allClients}
                        onSetClient={this.onSetClient}
                    />
                )}
                <PageTitle title={'Order details'} />
                <Header activePage={'Orders'} />
                <div className={'container container-order-details'}>
                    <Title title={'Orders details'} backLink={'/orders'} />
                    <OrderDetailsForm
                        order={activeOrder}
                        onSave={this.onSave}
                        onUpdate={this.onUpdateOrder}
                        onAddClient={this.onActivateClients}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ orders }) => {
    return orders
}

export default connect(mapStateToProps, {
    ...actions
})(withRouter(OrderDetailsScene))
