import React from 'react'
import moment from 'moment'
import TruncateString from 'react-truncate-string'
import CurrencyAmount from '../../../components/atoms/text/currencyPrice'
import { dateAddHours } from '../../../utilities/dateUtility'
import StatusInfo from '../../../components/atoms/text/statusInfo'
import ClientsList from './clientsList'

const OrderDetailsForm = ({ order = {}, onAddClient = () => {} }) => {
    const { quote = {}, pax, price, status = '' } = order || {}
    const { legs = [], ac = { images: [] } } = quote
    return (
        <div className={'columns'}>
            <div className={'column'}>
                <h2>{'Info'}</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <strong>{'PAX'}</strong>
                            </td>
                            <td>{pax}</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>{'Price'}</strong>
                            </td>
                            <td>
                                <CurrencyAmount
                                    amount={price}
                                    currency={quote.ac && quote.ac.currency}
                                />
                            </td>
                        </tr>
                        {/* {offer && ( */}
                        {/*    <tr> */}
                        {/*        <td> */}
                        {/*            <strong>{'Price per person'}</strong> */}
                        {/*        </td> */}
                        {/*        <td className={'price-per-person'}> */}
                        {/*            <CurrencyAmount */}
                        {/*                amount={offer.pricePerPerson || 0} */}
                        {/*                currency={quote.ac && quote.ac.currency} */}
                        {/*            /> */}
                        {/*        </td> */}
                        {/*    </tr> */}
                        {/* )} */}
                        <tr>
                            <td>{'Status'}</td>
                            <td>
                                <StatusInfo status={status || 'pending'} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>{'Requested at'}</strong>
                            </td>
                            <td>
                                {moment((order && order.created_at) || '').format('MMM DD YYYY')}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={'separator'} />
            <div className={'column'}>
                <h2 className={'ml1'}>{'Legs'}</h2>
                {legs.map((leg) => (
                    <div className={'flight-body leg-info'} key={leg.airportFrom._id}>
                        <div className={'departure'}>
                            <div className={'city-label'}>
                                <TruncateString text={leg.airportFrom.city} truncateAt={17} />
                            </div>
                            <p className={'location-lbl'}>{leg.airportFrom.icao}</p>
                            <p className={'dateLbl'}>
                                <strong>{moment(leg.fromDate).format('MMM DD')}</strong>
                                {moment(leg.fromDate).format('LT')}
                            </p>
                        </div>
                        <div className={'arrival'}>
                            <div className={'city-label'}>
                                <TruncateString text={leg.airportTo.city} truncateAt={17} />
                            </div>
                            <p className={'location-lbl'}>{leg.airportTo.icao}</p>
                            <p className={'dateLbl'}>
                                <strong>
                                    {moment(dateAddHours(leg.fromDate, quote.flightTimeDec)).format(
                                        'MMM DD'
                                    )}
                                </strong>
                                {moment(dateAddHours(leg.fromDate, quote.flightTimeDec)).format(
                                    'LT'
                                )}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
            <div className={'separator'} />
            <div className={'column'}>
                <h2>{'Clients'}</h2>
                <ClientsList order={order} onSetOrder={onAddClient} enableAdding={false} />

                <h2 className={'mt1'}>{'Aircraft'}</h2>
                <p>{ac.title}</p>
                <p>
                    <img src={ac.images[0]} alt={ac.title} />
                </p>
            </div>
        </div>
    )
}

export default OrderDetailsForm
