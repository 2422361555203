import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import InputField from '../../../components/atoms/form/inputField'
import ActionButton from '../../../components/atoms/buttons/actionButton'
import PageTitle from '../../../components/atoms/text/pageTitle'
import * as actions from '../duck/authActions'

class ResetPasswordScene extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: ''
        }
    }

    onReset = () => {
        // eslint-disable-next-line no-console
        const { history } = this.props
        history.push('/auth/login')
    }

    onUpdateField = (key, value) => {
        const newState = this.state
        newState[key] = value

        this.setState({
            ...newState
        })
    }

    render() {
        const { resetEmail } = this.props
        return (
            <div className={'reset-pass-page'}>
                <div className={'login-section login-image'}>
                    <span>{'Copyright &copy; 2020. EncoreJets'}</span>
                </div>
                <div className={'login-section'}>
                    <div className={'login-section-form'}>
                        <PageTitle title={'Reset password'} />
                        <h1>{'Reset password'}</h1>
                        <InputField
                            id={'resetEmail'}
                            placeholder={'E-mail'}
                            value={resetEmail}
                            onChange={this.onUpdateField}
                        />
                        <div className={'full-width'}>
                            <ActionButton
                                className={'btn is-primary'}
                                title={'Reset password'}
                                onClick={this.onReset}
                            />
                        </div>
                        <Link to={'/auth/login'}>{'Back to Login'}</Link>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { auth } = state
    return auth
}

export default connect(mapStateToProps, {
    ...actions
})(withRouter(ResetPasswordScene))
