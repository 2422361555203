import React from 'react'
import Select from 'react-select'
import DatePicker from 'react-date-picker'
import TimePicker from 'react-time-picker'

const InputField = ({
    type = 'text',
    id = 'id',
    placeholder = 'placeholder',
    onChange = () => null,
    value = null,
    isInvalid = false,
    maxValue = null,
    isSingleField = false,
    options = [],
    minDate = null,
    readOnly = false,
    label = null,
    noOptionMessage = 'No options'
}) => {
    // eslint-disable-next-line consistent-return
    const handleChange = (e) => {
        let newValue = null
        switch (type) {
            case 'number':
                newValue = parseInt(e.target.value.trim(), 10)
                newValue = newValue > 0 ? newValue : value
                if (maxValue !== null && maxValue < newValue) {
                    newValue = value
                }
                onChange(id, newValue)
                break
            case 'select':
                onChange(id, newValue)
                break
            // case 'date':
            default:
                onChange(id, e.target.value)
                break
        }
    }

    const colourStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: 'white',
            flex: 1,
            fontSize: 14,
            fontWeight: 'bold',
            fontFamily: 'Montserrat',
            border: 'none',
            borderRadius: 0
        }),
        option: (styles, { isSelected }) => {
            return {
                ...styles,
                color: isSelected ? 'white' : '#121212',
                fontSize: 14,
                paddingTop: 10,
                fontFamily: 'Montserrat',
                paddingBottom: 10,
                backgroundColor: isSelected ? '#c2b289' : 'white',
                ':hover': {
                    backgroundColor: '#c2b289'
                },
                ':active': {
                    backgroundColor: '#c2b289',
                    color: 'white'
                }
            }
        },

        input: () => () => ({ color: '#121212', fontFamily: 'Montserrat' }),
        placeholder: () => ({
            color: '#121212',
            paddingLeft: 10,
            fontFamily: 'Montserrat'
        }),
        singleValue: () => ({ color: '#121212', paddingLeft: 10, fontFamily: 'Montserrat' })
    }

    const selectedTime = () => {
        if (value === null) {
            return '10:00'
        }
        return value
    }
    const goldColor = '#c2b289'
    const colors = {
        text: goldColor,
        textLight: goldColor,
        primary: goldColor,
        primary75: goldColor,
        primary50: goldColor,
        primary25: goldColor,
        danger: goldColor,
        dangerLight: goldColor,
        options: 'white',
        neutral0: 'white'
    }

    return (
        <div
            className={`input-field ${type}-field ${isSingleField ? 'single-field' : ''} ${
                isInvalid && 'error-field'
            } ${label !== null ? 'select-with-label' : ''}`}>
            {label && (
                <p className={`label-container ${type === 'select' && 'select-label-container'}`}>
                    <label htmlFor={id}>{label}</label>
                </p>
            )}
            {type === 'select' && (
                <Select
                    name={id}
                    id={id}
                    styles={[colourStyles]}
                    label={placeholder}
                    onChange={(inputValue) => onChange(id, inputValue)}
                    defaultValue={value || ''}
                    className={'input'}
                    options={options}
                    onInputChange={(action) => onChange(`${id}Search`, action)}
                    placeholder={placeholder}
                    isClearable
                    classNamePref={'select-input'}
                    menuColor={'red'}
                    theme={{ ...Select.defaultTheme, colors }}
                    noOptionsMessage={noOptionMessage || (() => 'No options.')}
                    hideSelectedOptions={options.length === 0}
                />
            )}
            {type === 'date' && (
                <DatePicker
                    value={value || minDate}
                    onChange={(date) => onChange(id, date)}
                    minDate={minDate}
                    format={'M.d.y'}
                />
            )}
            {type === 'time' && (
                <TimePicker
                    value={selectedTime()}
                    onChange={(time) => onChange(id, time)}
                    format={'h:mm a'}
                    disableClock
                />
            )}
            {type === 'hidden' && <p style={{ minWidth: 100 }} />}
            {['number', 'text', 'password'].includes(type) && (
                <input
                    type={type}
                    name={id}
                    id={id}
                    placeholder={placeholder}
                    onChange={handleChange}
                    value={value || ''}
                    readOnly={readOnly}
                />
            )}
        </div>
    )
}

export default InputField
