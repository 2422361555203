import { toast } from 'react-toastify'

import { FLY_API, FLY_TOKEN } from '../config'
import { methodType, toJson, defaultHeaders } from '../commonApi'
import {
    AVIND_AIRPORT_ARRIV_SUCCESS,
    AVIND_AIRPORT_REQUEST
} from '../../../scenes/destinations/duck/types'

const AirportApiMiddleware = ({ dispatch }) => (next) => async (action) => {
    switch (action.type) {
        case AVIND_AIRPORT_REQUEST:
            try {
                const { city, type, index } = action.payload
                const response = await fetch(`${FLY_API}airports/search`, {
                    method: methodType.post,
                    headers: defaultHeaders,
                    body: toJson({
                        apiKey: FLY_TOKEN,
                        text: city
                    })
                })
                // toast('Something is not right. Loading airports error', { type: 'error' })
                if (response.status > 299) {
                    toast(response.statusText, { type: 'error' })
                    // showMessage({
                    //     message: 'Loading airports failed',
                    //     type: 'danger'
                    // })
                    // console.log()
                }
                response.json().then((body) => {
                    const { airports } = body

                    dispatch({
                        type: AVIND_AIRPORT_ARRIV_SUCCESS,
                        payload: {
                            airports: airports.map((item) => ({
                                label: `${item.name} ${item.city}`,
                                value: { ...item }
                            })),
                            type,
                            index
                        }
                    })
                })
            } catch (error) {
                toast(error.message, { type: 'error' })
            }
            break

        default:
            break
    }
    next(action)
}

export default AirportApiMiddleware
