import axios from 'axios'
import { BASE_URL } from './config'
import { objectFromStorage } from '../helpers/storageHelper'

export const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
}

export const authHeaders = () => {
    const token = objectFromStorage('token')

    return {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`
    }
}

export const methodType = {
    post: 'POST',
    get: 'GET',
    patch: 'PATCH',
    delete: 'DELETE'
}

export const memberjetHeaders = () => {
    const headers = {}
    headers['Ocp-Apim-Subscription-Key'] = 'b137c30825ab444b8f10c62e84449195' // '77c17f8b331e49569980d1b0227dc61a'
    return headers
}

export const toJson = (content) => {
    return JSON.stringify(content)
}

export const commonPost = async (path, body = {}) => {
    try {
        const response = await fetch(`${BASE_URL}${path}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        return await response.json()
    } catch (error) {
        return error.message
    }
}

export const commonPatch = async (path, body = {}) => {
    const result = await axios.patch(
        `${BASE_URL}${path}`,
        { ...body },
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    )
    // eslint-disable-next-line no-alert
    alert(result)
}
