import React from 'react'

const HourContainer = ({ items }) => {
    return (
        <div>
            {items.map((item) => {
                return (
                    <div key={item.title + item.value} className={'list-item'}>
                        <p className={'title-item'}>{item.title}</p>
                        <p className={'value-item'}>{item.value}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default HourContainer
