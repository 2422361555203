import { authHeaders, methodType } from '../commonApi'
import { BASE_URL } from '../config'

import {
    API_LOAD_DESTINATIONS_REQUEST,
    API_LOAD_DESTINATIONS_REQUEST_ERROR,
    API_LOAD_DESTINATIONS_REQUEST_SUCCESS
} from '../../../scenes/destinations/duck/types'

const DestinationApiMiddleware = ({ dispatch }) => (next) => async (action) => {
    const { type = '' } = action
    switch (type) {
        case API_LOAD_DESTINATIONS_REQUEST:
            try {
                const response = await fetch(`${BASE_URL}trip/list`, {
                    method: methodType.get,
                    headers: authHeaders()
                })
                const destinations = await response.json()
                const { error } = destinations
                if (error) {
                    dispatch({
                        type: API_LOAD_DESTINATIONS_REQUEST_ERROR,
                        payload: {
                            errorMessage: error,
                            isLoading: false
                        }
                    })
                    return
                }
                dispatch({
                    type: API_LOAD_DESTINATIONS_REQUEST_SUCCESS,
                    payload: { destinations }
                })
            } catch (error) {
                dispatch({
                    type: API_LOAD_DESTINATIONS_REQUEST_ERROR,
                    payload: {
                        errorMessage: error,
                        isLoading: false
                    }
                })
            }
            break
        default:
            break
    }
    next(action)
}

export default DestinationApiMiddleware
