import React from 'react'
import { Link } from 'react-router-dom'
import ClientItem from './ClientItem'

const ClientsList = ({ clients = [], onDelete = () => null, onPressClient = () => null }) => {
    return (
        <div className={'container-client-list'}>
            <Link to={'/client/new'} className={'button-for-navigation'}>
                {'+ ADD NEW CLIENT'}
            </Link>
            <table className={'clients-list'}>
                <thead>
                    <tr>
                        <th>{'Select all'}</th>
                        <th className={'left-align'}>{'Name'}</th>
                        <th className={'left-align'}>{'E-mail'}</th>
                        <th>{'Status'}</th>
                        <th>{'Membership'}</th>
                        <th>{'Flight gross'}</th>
                        <th>{'$ Gross/Commission'}</th>
                    </tr>
                </thead>
                <tbody>
                    {clients &&
                        clients.map((client) => (
                            <ClientItem
                                client={client}
                                onDelete={onDelete}
                                onPressClient={onPressClient}
                                key={
                                    // eslint-disable-next-line no-underscore-dangle
                                    client._id && client._id.length > 5
                                        ? // eslint-disable-next-line no-underscore-dangle
                                          client._id
                                        : Math.random()
                                }
                            />
                        ))}
                </tbody>
            </table>
        </div>
    )
}

export default ClientsList
