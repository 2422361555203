import React from 'react'
import TabBarTitle from '../../../../components/atoms/buttons/tabBarTitle'
import HourContainer from './components/hourContainer'
import WeekContainer from './components/weekContainer'
import MonthContainer from './components/monthContainer'
import { countFlights } from '../../duck/selectors'

const QuickDetails = ({ activeTab, onHandlerTabsInQuickCard = () => {}, orders = [] }) => {
    const items = [
        { title: 'Booked flights', value: countFlights(orders) },
        { title: 'Requested ', value: countFlights(orders) }
    ]
    return (
        <div className={'content card-container-items'}>
            <h2 className={'title-container'}>{'QUICK DETAILS'}</h2>
            <div className={'title-border-dashboard'} />
            <TabBarTitle
                className={'tab-bar-title-dashboard'}
                tabs={['24 h', 'Week', 'Month']}
                activeTab={activeTab}
                onChange={onHandlerTabsInQuickCard}
            />
            {activeTab === '24 h' && <HourContainer items={items} />}
            {activeTab === 'Week' && <WeekContainer items={items} />}
            {activeTab === 'Month' && <MonthContainer items={items} />}
        </div>
    )
}

export default QuickDetails
