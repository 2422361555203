import { toast } from 'react-toastify'

export const ordersList = (activeTab, { upcoming, previous }) => {
    switch (activeTab) {
        case 'Previous':
            return [...previous]

        default:
            return [...upcoming]
    }
}

export const getUniqueClients = (clients, client) => {
    return clients.filter((listClient) => {
        return listClient.user._id !== client.user._id
    })
}

export const getTotalPrice = (order, pricePerPerson) => {
    const { pax = 1 } = order || {}
    return pax * pricePerPerson
}

export const isOfferValid = (order, clients) => {
    const { pax = 0 } = order
    if (pax > clients.length) {
        toast(`Offer is not complete! \nNumber of clients should be at least ${pax}`, {
            type: 'error'
        })
        return false
    }

    return true
}
