import React from 'react'
// import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Title from '../../../../components/atoms/text/title/title'
import Display from './cardClients'

const ClientMembership = ({ onUpdate = () => null, client = {} }) => {
    return (
        <div className={'client-advanced-container'}>
            <div className={'container-for-slider'}>
                <Title title={'Choose membership for client'} />
                <div className={'slider'}>
                    <Display />
                    <div className={'text-container'}>
                        <Title title={'Add note about client'} />
                        <textarea
                            className={'text'}
                            onChange={(e) => {
                                const { value } = e.target
                                onUpdate('note', value)
                            }}
                            value={(client && client.note) || ''}
                        />
                    </div>
                </div>
            </div>

            {/* <div className={'container-for-message'}> */}
            {/*    <div className={'messages-box'}> */}
            {/*        {messages.map((message) => ( */}
            {/*            <div key={message} className={'message'}> */}
            {/*                {message} */}
            {/*            </div> */}
            {/*        ))} */}
            {/*    </div> */}
            {/*    <div className={'inside-button'}> */}
            {/*        <input placeholder={'Chat with client'} className={'input-chat'} /> */}
            {/*        <button id={'inside-button'} className={'button-chat'}> */}
            {/*            <FontAwesomeIcon icon={faPaperPlane} /> */}
            {/*        </button> */}
            {/*    </div> */}
            {/* </div> */}
        </div>
    )
}

export default ClientMembership
