import moment from 'moment'
import { dateAddHours } from './dateUtility'

export const flightDestinations = (flight) => {
    const { legs = [] } = flight || {}
    const departure = (legs.length > 0 && legs[0]) || {}
    return `${departure.airportFrom && departure.airportFrom.icao} - ${
        departure.airportTo && departure.airportTo.icao
    }`
}

export const flightID = (flight) => {
    // eslint-disable-next-line no-underscore-dangle
    return flight.id || flight._id
}

export const flightDepartureDateTime = (flight) => {
    const { legs = [], flightTimeDec = '' } = flight || {}
    const departure = legs[0] || {}
    if (flightTimeDec === '') {
        return ''
    }
    return `${moment(dateAddHours(departure.fromDate, flightTimeDec)).format('MMM DD')} (${moment(
        dateAddHours(departure.fromDate, flightTimeDec)
    ).format('LT')})`
}
