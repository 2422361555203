import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import InputField from '../../../components/atoms/form/inputField'
import ActionButton from '../../../components/atoms/buttons/actionButton'

const OneWaySection = ({
    onSearchFlights = () => null,
    filter = {},
    onFilterUpdated = () => null,
    airports = [],
    onSearchForAirports = () => null,
    onResetFlight = () => null
}) => {
    const onUpdateFilter = (key, value) => {
        const newState = { ...filter }
        switch (key) {
            case 'fromSearch':
            case 'toSearch':
                if (value.length > 2) {
                    onSearchForAirports(value, key.replace('Search', ''), 0)
                }
                break

            default:
                newState[key] = value
                onFilterUpdated('oneWayFilter', newState)
                break
        }
    }

    const onSubmitSearch = () => {
        onSearchFlights(filter)
    }

    return (
        <div className={'one-way-container'}>
            <div className={'filter-background'}>
                <div className={'title-container'}>
                    <h2>{'Search flights'}</h2>
                    {(filter.from || filter.to) && (
                        <button className={'button-for-reset'} onClick={onResetFlight}>
                            {'CLEAR SEARCH'}
                        </button>
                    )}
                </div>
                <div className={'form-content'}>
                    <div className={'flight-search-box'}>
                        <InputField
                            type={'select'}
                            id={'from'}
                            placeholder={'From where?'}
                            value={filter.from}
                            onChange={onUpdateFilter}
                            isSingleField
                            options={airports}
                            noOptionMessage={() => 'Enter airport name or city.'}
                        />
                        <FontAwesomeIcon icon={faPaperPlane} className={'icon'} />
                        <InputField
                            type={'select'}
                            placeholder={'Where to?'}
                            value={filter.to}
                            id={'to'}
                            onChange={onUpdateFilter}
                            isSingleField
                            options={airports}
                            noOptionMessage={() => 'Enter airport name or city.'}
                        />
                    </div>
                    <div className={'separator'} />
                    <div className={'time-date-box'}>
                        <InputField
                            type={'date'}
                            id={'date'}
                            placeholder={'Departure date?'}
                            value={filter.date}
                            onChange={onUpdateFilter}
                            isSingleField
                            className={'test'}
                        />
                        <InputField
                            type={'time'}
                            id={'time'}
                            placeholder={'Pick time'}
                            value={filter.time}
                            onChange={onUpdateFilter}
                            isSingleField
                        />
                    </div>
                    <div className={'separator'} />
                    <InputField
                        type={'number'}
                        id={'numberOfSeats'}
                        placeholder={'Number of seats'}
                        value={filter.numberOfSeats || 1}
                        onChange={onUpdateFilter}
                        maxValue={20}
                        isSingleField
                    />
                    <div className={'footer-container'}>
                        <ActionButton onClick={onSubmitSearch} title={'Search'} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OneWaySection
