import React from 'react'
import ActionButton from '../../../components/atoms/buttons/actionButton'
import StatusInfo from '../../../components/atoms/text/statusInfo'

const ClientsList = ({ order, onSetOrder = () => null, enableAdding = true }) => {
    const { clients = [], offer = {} } = order || {}
    if (offer) {
        const clientsList = offer.clients
        return clientsList ? (
            <table>
                <tbody>
                    {clientsList.map((client) => (
                        <tr key={client._id}>
                            <td>
                                <a href={`/client/${client._id}`}>
                                    {client.user.firstName} {client.user.lastName}
                                </a>
                            </td>
                            <td>{StatusInfo({ status: client.status || 'pending' })}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ) : (
            <div />
        )
    }
    if (clients.length > 0) {
        return clients.map((client) => (
            <a href={`/client/${client.id}`} key={client.id}>
                {client.name}
            </a>
        ))
    }
    return (
        <div>
            <p className={'missing-info'}>{'No sent quotes.'}</p>
            {enableAdding && (
                <ActionButton
                    className={'wire-button'}
                    title={'Add clients'}
                    onClick={() => onSetOrder(order)}
                    isDark={false}
                />
            )}
        </div>
    )
}

export default ClientsList
