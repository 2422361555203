import React from 'react'

const TabBarTitle = ({
    tabs = ['Title'],
    onChange = () => null,
    activeTab = '',
    className = ''
}) => {
    return (
        <div className={'tab-bar-title'}>
            {tabs.map((tab) => (
                <TabItem
                    title={tab}
                    onClick={() => onChange(tab)}
                    isActive={tab === activeTab}
                    key={tab}
                    className={className}
                />
            ))}
        </div>
    )
}

export default TabBarTitle

const TabItem = ({ title = '', onClick = () => null, isActive = false, className = '' }) => {
    return (
        // eslint-disable-next-line react/button-has-type
        <button className={`tab-item ${isActive && 'active'}`} onClick={onClick}>
            <p className={className}>{title}</p>
        </button>
    )
}
