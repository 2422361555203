import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const ClientInfo = ({ client, onClose }) => {
    const { firstName, lastName, email, phone, city, state } = client.user

    const renderField = (field, key) => {
        return field.length > 3 ? (
            <p>
                <strong>{`${key}: `}</strong>
                {field}
            </p>
        ) : null
    }
    return (
        <div className={'client-info-container'}>
            <div className={'info-content'}>
                <div className={'client-content'}>
                    <h1>
                        {firstName || ''} {lastName || ''}
                    </h1>
                    {renderField(email, 'Email')}
                    {renderField(phone, 'Phone')}
                    {renderField(`${city || ''} ${state || ''}`, 'City / State')}
                </div>
                <div>
                    <button className={'close-button'} onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ClientInfo
