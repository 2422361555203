import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const ClientsPicker = ({ clients = [], onAddClient = () => null }) => {
    return (
        <div className={'client-picker'}>
            {clients.map((client) => (
                <div className={'client-picker-item'} key={`picker${client.user._id}`}>
                    <button title={'Add client'} onClick={() => onAddClient(client)}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                    <p>
                        {`${client.user.firstName} ${client.user.lastName}`}
                        <span>{client.user.email}</span>
                    </p>
                </div>
            ))}
        </div>
    )
}

export default ClientsPicker
