import React from 'react'
import Cards from '../../../../components/atoms/card/cards'
import MyResponsiveBar from '../../../../components/atoms/charts/charts'

const DiagramContainer = ({ activeCard, onHandleCardsInDiagramContainer = () => {} }) => {
    return (
        <div className={'container-diagram-dashboard'}>
            <Cards
                className={'card-container card title-card'}
                cards={[
                    { title: 'Total revenue', price: 'USD 45 000' },
                    { title: 'Flight flown', price: '6000' },
                    { title: 'Average margin percenatge', price: '45%' }
                ]}
                activeCard={activeCard}
                onChange={onHandleCardsInDiagramContainer}
            />
            <div className={'container-for-title'}>
                <div>
                    <h2 className={'title-container'}>{'TOTAL REVENUE'}</h2>
                    <div className={'title-border-dashboard'} />
                </div>
                <select name={'items'} id={'items'} className={'selector'}>
                    <option className={'selector-option'} value={'THIS MONTH'}>
                        {'THIS MONTH'}
                    </option>
                    <option className={'selector-option'} value={'THIS WEEK'}>
                        {'THIS WEEK'}
                    </option>
                    <option className={'selector-option'} value={'THIS YEAR'}>
                        {'THIS YEAR'}
                    </option>
                </select>
            </div>
            <MyResponsiveBar
                data={[
                    {
                        country: 'AD',
                        'hot dog': 8,
                        'hot dogColor': 'hsl(294, 70%, 50%)',
                        burger: 8,
                        burgerColor: 'hsl(166, 70%, 50%)',
                        sandwich: 8,
                        sandwichColor: 'hsl(72, 70%, 50%)',
                        kebab: 8,
                        kebabColor: 'hsl(86, 70%, 50%)',
                        fries: 8,
                        friesColor: 'hsl(313, 70%, 50%)',
                        donut: 8,
                        donutColor: 'hsl(181, 70%, 50%)'
                    },
                    {
                        country: 'AE',
                        'hot dog': 8,
                        'hot dogColor': 'hsl(201, 70%, 50%)',
                        burger: 8,
                        burgerColor: 'hsl(329, 70%, 50%)',
                        sandwich: 8,
                        sandwichColor: 'hsl(290, 70%, 50%)',
                        kebab: 8,
                        kebabColor: 'hsl(50, 70%, 50%)',
                        fries: 8,
                        friesColor: 'hsl(194, 70%, 50%)',
                        donut: 8,
                        donutColor: 'hsl(3, 70%, 50%)'
                    },
                    {
                        country: 'AF',
                        'hot dog': 8,
                        'hot dogColor': 'hsl(147, 70%, 50%)',
                        burger: 8,
                        burgerColor: 'hsl(340, 70%, 50%)',
                        sandwich: 8,
                        sandwichColor: 'hsl(241, 70%, 50%)',
                        kebab: 8,
                        kebabColor: 'hsl(141, 70%, 50%)',
                        fries: 8,
                        friesColor: 'hsl(71, 70%, 50%)',
                        donut: 8,
                        donutColor: 'hsl(149, 70%, 50%)'
                    },
                    {
                        country: 'AG',
                        'hot dog': 5,
                        'hot dogColor': 'hsl(1, 70%, 50%)',
                        burger: 8,
                        burgerColor: 'hsl(332, 70%, 50%)',
                        sandwich: 8,
                        sandwichColor: 'hsl(158, 70%, 50%)',
                        kebab: 8,
                        kebabColor: 'hsl(76, 70%, 50%)',
                        fries: 3,
                        friesColor: 'hsl(172, 70%, 50%)',
                        donut: 8,
                        donutColor: 'hsl(184, 70%, 50%)'
                    },
                    {
                        country: 'AI',
                        'hot dog': 8,
                        'hot dogColor': 'hsl(103, 70%, 50%)',
                        burger: 8,
                        burgerColor: 'hsl(345, 70%, 50%)',
                        sandwich: 8,
                        sandwichColor: 'hsl(276, 70%, 50%)',
                        kebab: 8,
                        kebabColor: 'hsl(325, 70%, 50%)',
                        fries: 8,
                        friesColor: 'hsl(325, 70%, 50%)',
                        donut: 8,
                        donutColor: 'hsl(112, 70%, 50%)'
                    },
                    {
                        country: 'AL',
                        'hot dog': 8,
                        'hot dogColor': 'hsl(66, 70%, 50%)',
                        burger: 8,
                        burgerColor: 'hsl(140, 70%, 50%)',
                        sandwich: 8,
                        sandwichColor: 'hsl(220, 70%, 50%)',
                        kebab: 8,
                        kebabColor: 'hsl(350, 70%, 50%)',
                        fries: 8,
                        friesColor: 'hsl(135, 70%, 50%)',
                        donut: 8,
                        donutColor: 'hsl(176, 70%, 50%)'
                    },
                    {
                        country: 'AM',
                        'hot dog': 8,
                        'hot dogColor': 'hsl(331, 70%, 50%)',
                        burger: 8,
                        burgerColor: 'hsl(67, 70%, 50%)',
                        sandwich: 8,
                        sandwichColor: 'hsl(251, 70%, 50%)',
                        kebab: 8,
                        kebabColor: 'hsl(225, 70%, 50%)',
                        fries: 8,
                        friesColor: 'hsl(325, 70%, 50%)',
                        donut: 20,
                        donutColor: 'hsl(101, 70%, 50%)'
                    }
                ]}
            />
        </div>
    )
}

export default DiagramContainer
