import React from 'react'

const Cards = ({ cards = [], activeCard = '', onChange = () => null, className = '' }) => {
    return (
        <div className={`card-container`}>
            {cards.map((card) => (
                <Card
                    title={card.title}
                    cardPrice={card.price}
                    key={card.title}
                    isActive={card.title === activeCard}
                    onClick={() => onChange(card.title)}
                    className={className}
                />
            ))}
        </div>
    )
}

export default Cards

const Card = ({
    title = '',
    cardPrice = '',
    isActive = false,
    onClick = () => null,
    className = ''
}) => {
    return (
        <button onClick={onClick} className={`content ${isActive && 'activeCard'} ${className}`}>
            <div>
                <p className={'titleCard'}>{title}</p>
                <p className={'titleCard price'}>{cardPrice}</p>
            </div>
        </button>
    )
}
