import React from 'react'

function CardData() {
    const rtn = [
        {
            title: 'ELITE',
            subtitle: 'Membership',
            info: 'For spent > 50K in one year',
            price: '3%'
        },
        {
            title: 'WORLD',
            subtitle: 'Membership',
            info: 'For spent > 100K in one year',
            price: '5%'
        },
        {
            title: 'SUPER CLASS',
            subtitle: 'Membership',
            info: 'For spent > 200K in one year',
            price: '7%'
        }
    ]
    return rtn
}

const Cards = ({ cardStyle }) => {
    const cardData = CardData()
    return (
        <section>
            {cardData.map((card) => {
                const { title, subtitle, price, info } = card
                return (
                    <div className={'card'} key={info} id={'card'} style={cardStyle}>
                        <p className={'title-cards'}>{title}</p>
                        <p className={'desc'}>{subtitle}</p>
                        <p className={'price'}>
                            <strong>{price}</strong> {info}
                        </p>
                    </div>
                )
            })}
        </section>
    )
}

class Display extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentCard: 0,
            position: 0,
            cardStyle: {
                transform: 'translateX(0px)'
            },
            width: 0
        }
    }

    componentDidMount() {
        const boxWidth = document.getElementById('card').clientWidth
        this.setState({ width: boxWidth })
    }

    // func: click the slider buttons
    // eslint-disable-next-line react/sort-comp
    handleClick(type) {
        const { width } = this.state
        // get the card's margin-right
        let margin = window.getComputedStyle(document.getElementById('card')).marginRight
        margin = JSON.parse(margin.replace(/px/i, ''))

        const cardWidth = width // the card's width
        const cardMargin = margin // the card's margin
        const cardNumber = CardData().length // the number of cards
        let { currentCard, position } = this.state // the index of the current card

        // slide cards
        if (type === 'next' && currentCard < cardNumber - 1) {
            // eslint-disable-next-line no-plusplus
            currentCard++
            position -= cardWidth + cardMargin
        } else if (type === 'prev' && currentCard > 0) {
            // eslint-disable-next-line no-plusplus
            currentCard--
            position += cardWidth + cardMargin
        }
        this.setCard(currentCard, position)
    }

    setCard(currentCard, position) {
        this.setState({
            currentCard,
            position,
            cardStyle: {
                transform: `translateX(${position}px)`
            }
        })
    }

    render() {
        const { cardStyle } = this.state
        return (
            <div className={'cards-slider'}>
                <div className={'slider-btns'}>
                    <button className={'slider-btn btn-l'} onClick={() => this.handleClick('prev')}>
                        &lt;
                    </button>
                    <button className={'slider-btn btn-r'} onClick={() => this.handleClick('next')}>
                        &gt;
                    </button>
                </div>
                <Cards cardStyle={cardStyle} />
            </div>
        )
    }
}

export default Display
