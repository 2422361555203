import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { applyMiddleware, createStore } from 'redux'
import rootReducer from '../../reducers'
import { initFonts } from '../../utilities/config'
import LoginScene from './login/LoginScene'
import ResetPasswordScene from './reset/ResetPasswordScene'
import LogoutScene from './logout/LogoutScene'

class AuthScene extends React.Component {
    constructor(props) {
        super(props)

        const enhancer = composeWithDevTools(applyMiddleware(thunk))
        this.store = createStore(rootReducer, enhancer)
    }

    componentDidMount() {
        initFonts()
    }

    render() {
        return (
            <Switch>
                <Route path={'/'} component={LoginScene} />
                <Route path={'/auth/login'} component={LoginScene} />
                <Route path={'/auth/reset-password'} component={ResetPasswordScene} />
                <Route path={'/auth/logout'} component={LogoutScene} />
            </Switch>
        )
    }
}

export default AuthScene
