import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import ActionButton from '../../../components/atoms/buttons/actionButton'

const OfferConfirmation = ({
    onClose = () => null,
    onChangeMessageBody = () => null,
    messageBody = 'Test',
    onSubmit = () => null
}) => {
    return (
        <div className={'order-client-container offer-confirmation'}>
            <div className={'order-client-content'}>
                <div className={'client-content'}>
                    <div className={'titleContent'}>
                        <h1>{'Send offer to clients'}</h1>
                        <button className={'close-button'} onClick={() => onClose(null)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                    <h2>{'Your personalized message'}</h2>

                    <textarea onChange={onChangeMessageBody} value={messageBody} />
                    <p className={'info-note'}>
                        <strong>{'Note: '}</strong>
                        {'[] are placeholders, which will be pre-populated before sending. '}
                        <br />
                        <strong>{'Available placeholders:'}</strong>
                        <br />
                        {"[firstName] or [lastName] - client's first/last name "}
                        {"[email] - client's email address "}
                        {
                            "[brokerFirstName] or [brokerLastName] - logged broker's first/last name (you 😊)"
                        }{' '}
                        <br />
                        {'[brokerSignature] - your signature defined in profile page'}
                        <br />
                    </p>
                    <div className={'add-control'}>
                        <ActionButton
                            // className={'add-client-button'}
                            title={'Send offer'}
                            onClick={() => onSubmit(null)}
                        />
                        <ActionButton
                            className={'button-cancel'}
                            title={'Cancel'}
                            onClick={() => onClose(null)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OfferConfirmation
