import React from 'react'
import ClientsContainer from './components/clientsContainer/clientsContainer'
import UpdateUser from './components/updateUser/updateUser'

class InviteNewClient extends React.PureComponent {
    render() {
        return (
            <div className={'invite-new-client-container'}>
                <ClientsContainer />
                <UpdateUser />
            </div>
        )
    }
}

export default InviteNewClient
