import {
    API_LOAD_CLIENT_DETAILS_REQUEST,
    API_LOAD_CLIENTS_REQUEST,
    API_SEARCH_CLIENTS_REQUEST,
    DELETE_CLIENT_DETAILS_REQUEST,
    STORE_CLIENT_DETAILS_REQUEST,
    UPDATE_CLIENT_DETAILS,
    UPDATE_SEARCH_FIELD,
    API_SEARCH_FIELD_REQUEST
} from './types'

export const loadClients = () => (dispatch) => {
    dispatch({
        type: API_LOAD_CLIENTS_REQUEST,
        payload: {}
    })
}

export const searchClients = (search) => (dispatch) => {
    dispatch({
        type: API_SEARCH_CLIENTS_REQUEST,
        payload: { search }
    })
}

export const loadClientDetails = (id) => ({
    type: API_LOAD_CLIENT_DETAILS_REQUEST,
    payload: { id }
})

export const updateClientDetails = (client) => ({
    type: UPDATE_CLIENT_DETAILS,
    payload: client
})

export const saveClient = (clientDetails) => ({
    type: STORE_CLIENT_DETAILS_REQUEST,
    payload: clientDetails
})

export const deleteClient = (id) => ({
    type: DELETE_CLIENT_DETAILS_REQUEST,
    payload: { id }
})

export const updateSearch = (searchField) => ({
    type: UPDATE_SEARCH_FIELD,
    payload: searchField
})

export const onSubmitSearch = (searchField) => {
    return {
        type: API_SEARCH_FIELD_REQUEST,
        payload: searchField
    }
}
