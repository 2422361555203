import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImage, faPaintBrush } from '@fortawesome/free-solid-svg-icons'
import Title from '../../../../../../../components/atoms/text/title/title'
import ActionButton from '../../../../../../../components/atoms/buttons/actionButton'

const UpdateUser = () => {
    return (
        <div className={'container-update-user'}>
            <div className={'container-for-update'}>
                <Title title={'Choose template'} />
                <div>
                    <div className={'container-change-color'}>
                        <Title title={'Choose background color'} />
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button className={'button-color-white'} />
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button className={'button-color-black'} />
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button className={'button-color-gold'} />
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button className={'button-choose-action'}>
                            <FontAwesomeIcon icon={faPaintBrush} />
                        </button>
                    </div>
                    <div className={'container-change-color'}>
                        <Title title={'Choose text color'} />
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button className={'button-color-white'} />
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button className={'button-color-black'} />
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button className={'button-color-gold'} />
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button className={'button-choose-action'}>
                            <FontAwesomeIcon icon={faPaintBrush} />
                        </button>
                    </div>
                    <div className={'container-change-color'}>
                        <Title title={'Choose font style'} />
                        <select className={'select-input'}>
                            <option>{'Font Family'}</option>
                        </select>
                        <select className={'select-input'}>
                            <option>{'Paragraph style'}</option>
                        </select>
                    </div>
                    <div className={'container-for-image'}>
                        <label htmlFor={'button'} className={'icon-for-input-button'}>
                            <FontAwesomeIcon icon={faFileImage} />
                            <p className={'text-button'}>{'Upload images'}</p>
                        </label>

                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label>
                            <p className={'description'}>
                                {'First uploaded image will be user as cover'}
                            </p>
                        </label>
                        <input type={'file'} id={'button'} className={'input-for-images'} />
                    </div>
                </div>
                <div className={'button-container-details'}>
                    <ActionButton title={'Send'} className={'button-save'} isDark={false} />
                    <ActionButton className={'button-cancel'} title={'Save'} isDark={false} />
                </div>
            </div>
        </div>
    )
}

export default UpdateUser
