import React from 'react'
import Header from '../../components/organisms/header'

class FAAScene extends React.PureComponent {
    render() {
        return (
            <div>
                <Header activePage={'FAA'} />
                <div className={'container'}>
                    <h1>{'FAA'}</h1>
                </div>
            </div>
        )
    }
}

export default FAAScene
