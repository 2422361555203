import { toast } from 'react-toastify'
import {
    API_LOGIN_REQUEST,
    API_LOGIN_REQUEST_ERROR,
    API_LOGIN_REQUEST_SUCCESS,
    API_PROFILE_REQUEST,
    API_PROFILE_REQUEST_SUCCESS
} from '../../../scenes/auth/duck/authTypes'
import { authHeaders, defaultHeaders, methodType, toJson } from '../commonApi'
import { BASE_URL } from '../config'
import { objectToStorage } from '../../helpers/storageHelper'
import { shouldLogout } from '../../../utilities/authUtility'
import { API_UPDATE_PROFILE_FORM_REQUEST } from '../../../scenes/dashboard/duck/dashTypes'

const AuthApiMiddleware = ({ dispatch }) => (next) => async (action) => {
    const { type = '' } = action
    switch (type) {
        case API_LOGIN_REQUEST:
            try {
                const response = await fetch(`${BASE_URL}auth/login`, {
                    method: methodType.post,
                    headers: defaultHeaders,
                    body: toJson({
                        email: action.payload.email,
                        password: action.payload.password,
                        role: 'broker'
                    })
                })
                const responseJson = await response.json()
                const { success, error } = responseJson
                if (error) {
                    dispatch({
                        type: API_LOGIN_REQUEST_ERROR,
                        payload: {
                            errorMessage: error,
                            errors: [error],
                            shouldValidate: true,
                            isLoading: false
                        }
                    })
                    return
                }
                const { token } = success
                if (token) {
                    objectToStorage('auth', { authenticationToken: token })
                    objectToStorage('token', token)
                    const { history } = action.payload
                    if (history) {
                        setTimeout(() => {
                            history.push('/dashboard')
                            dispatch({
                                type: API_LOGIN_REQUEST_SUCCESS,
                                payload: { token, redirectTo: true }
                            })
                        }, 500)
                    }
                    return
                }
            } catch (error) {
                // console.log(error)
                dispatch({
                    type: API_LOGIN_REQUEST_ERROR,
                    payload: {
                        errorMessage: error,
                        errors: [error],
                        shouldValidate: true,
                        isLoading: false
                    }
                })
            }
            break
        case API_PROFILE_REQUEST:
            try {
                const response = await fetch(`${BASE_URL}user/me/profile`, {
                    method: methodType.get,
                    headers: authHeaders()
                })
                const responseJson = await response.json()
                const { userID } = responseJson
                if (userID) {
                    objectToStorage('userProfile', responseJson)
                    dispatch({
                        type: API_PROFILE_REQUEST_SUCCESS,
                        payload: responseJson
                    })
                }
                shouldLogout(responseJson)
            } catch (error) {
                dispatch({
                    type: API_LOGIN_REQUEST_ERROR,
                    payload: {
                        errorMessage: error,
                        errors: [error],
                        shouldValidate: true,
                        isLoading: false
                    }
                })
            }
            break
        case API_UPDATE_PROFILE_FORM_REQUEST:
            try {
                const response = await fetch(`${BASE_URL}user/profile`, {
                    method: methodType.post,
                    headers: authHeaders(),
                    body: toJson(action.payload)
                })
                // const responseJson = await response.json()
                if (response.status === 200) {
                    toast('Profile stored successfully.', { type: 'success' })
                    dispatch({
                        type: API_PROFILE_REQUEST
                    })
                }
            } catch (error) {
                toast(error.message, { type: 'error' })
            }
            break
        default:
            break
    }
    next(action)
}

export default AuthApiMiddleware
