import React from 'react'
import moment from 'moment'
import CurrencyAmount from '../../../components/atoms/text/currencyPrice'
import StatusInfo from '../../../components/atoms/text/statusInfo'
import ClientsPicker from '../../destinations/components/clientsPicker'
import ActionButton from '../../../components/atoms/buttons/actionButton'
import ClientItem from './clientItem'
import LegsInfo from './legsInfo'
import ClientsList from './clientsList'

const OfferForm = ({
    order = {},
    clients = [],
    onAddClient = () => null,
    onRemoveClient = () => null,
    onSubmit = () => null,
    onCancel = () => null,
    isNew = false,
    selectedClients = [
        {
            user: {
                firstName: 'pedja',
                lastName: 'jev',
                email: 'me@asdf.com'
            }
        }
    ]
}) => {
    const { quote = {}, pax, price, status = '' } = order || {}
    const { legs = [] } = quote || {}

    return (
        <div className={'columns'}>
            <div className={'column'}>
                <h2>{'Order details'}</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <strong>{'PAX'}</strong>
                            </td>
                            <td>{pax}</td>
                        </tr>
                        <tr>
                            <td>{'Flight Order Costs'}</td>
                            <td>
                                {' '}
                                <CurrencyAmount
                                    amount={price}
                                    currency={quote.ac && quote.ac.currency}
                                />
                            </td>
                        </tr>
                        {/* <tr> */}
                        {/*    <td style={{ width: '50%' }}> */}
                        {/*        <strong>{'Total price + profit'}</strong> */}
                        {/*    </td> */}
                        {/*    <td style={{ width: '50%' }}> */}
                        {/*        <strong>{'Flight Order Costs'}</strong> */}
                        {/*    </td> */}
                        {/* </tr> */}
                        {/* <tr> */}
                        {/*    <td style={{ width: '50%' }}> */}
                        {/*        <CurrencyAmount */}
                        {/*            amount={totalPrice} */}
                        {/*            currency={quote.ac && quote.ac.currency} */}
                        {/*        /> */}
                        {/*    </td> */}
                        {/*    <td style={{ width: '50%' }}> */}
                        {/*        <CurrencyAmount */}
                        {/*            amount={price} */}
                        {/*            currency={quote.ac && quote.ac.currency} */}
                        {/*        /> */}
                        {/*    </td> */}
                        {/* </tr> */}
                        <tr>
                            <td>{'Status'}</td>
                            <td>
                                <StatusInfo status={status || 'pending'} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>{'Requested at'}</strong>
                            </td>
                            <td>
                                {moment((order && order.created_at) || '').format('MMM DD YYYY')}
                            </td>
                        </tr>
                    </tbody>
                </table>
                {isNew && <LegsInfo quote={quote} legs={legs} />}
            </div>
            <div className={'separator'} />
            {isNew ? (
                <div className={'column'}>
                    <h2>{'Select clients'}</h2>
                    <ClientsPicker clients={clients} onAddClient={onAddClient} />
                </div>
            ) : (
                <div className={'column'}>
                    <LegsInfo quote={quote} legs={legs} />
                </div>
            )}

            <div className={'separator'} />
            <div className={'column selected-clients'}>
                <div>
                    <h2>{isNew ? 'Chosen clients' : 'Contacted clients'}</h2>
                    {isNew ? (
                        <div className={'selected-clients-list'}>
                            {selectedClients.map((client) => (
                                <ClientItem
                                    client={client}
                                    key={`selected_${client.user._id}`}
                                    onDelete={onRemoveClient}
                                />
                            ))}
                            {selectedClients.length < 1 && (
                                <p>
                                    {
                                        'Add clients to this order by pressing plus button next to client name.'
                                    }
                                </p>
                            )}
                        </div>
                    ) : (
                        <ClientsList order={order} />
                    )}
                </div>
                {isNew && (
                    <div className={'button-container-details'}>
                        <ActionButton title={'Send'} onClick={onSubmit} />
                        <ActionButton
                            className={'button-cancel'}
                            title={'Cancel'}
                            onClick={onCancel}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default OfferForm
