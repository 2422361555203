import React from 'react'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InputField from '../../../components/atoms/form/inputField'
import ActionButton from '../../../components/atoms/buttons/actionButton'

const RoundTripSection = ({
    onSearchFlights = () => null,
    filter = {},
    onFilterUpdated = () => null,
    airports = [],
    onSearchForAirports = () => null,
    onResetFlight = () => null
}) => {
    const onUpdateFilter = (key, value) => {
        const newState = { ...filter }
        const departure = filter.legs[0] || {}
        const arrival = filter.legs[1] || {}
        newState[key] = value
        switch (key) {
            case 'fromSearch':
            case 'toSearch':
                if (value.length > 2) {
                    onSearchForAirports(value, key.replace('Search', ''), 0)
                }
                break
            case 'returnFromSearch':
                if (value.length > 2) {
                    onSearchForAirports(value, 'from', 1)
                }
                break
            case 'returnToSearch':
                if (value.length > 2) {
                    onSearchForAirports(value, 'to', 1)
                }
                break
            case 'returnFrom':
                arrival.from = value
                departure.to = value
                break
            case 'returnTo':
                arrival.to = value
                departure.from = value
                break
            case 'numberOfSeats':
                newState.numberOfSeats = value
                break
            case 'returnDate':
                arrival.date = value
                break
            case 'returnTime':
                arrival.time = value
                break
            case 'date':
            case 'time':
                departure[key] = value
                break
            case 'from':
                departure.from = value
                arrival.to = value
                break
            case 'to':
                departure.to = value
                arrival.from = value
                break

            default:
                newState.legs[0] = { ...departure }
                break
        }
        newState.legs = [{ ...departure }, { ...arrival }]
        onFilterUpdated('roundTripFilter', newState)
    }

    const onSubmitSearch = () => {
        const newState = { ...filter }
        const departure = filter.legs[0] || {}
        const arrival = filter.legs[1] || {}
        arrival.from = departure.to
        arrival.to = departure.from
        newState.legs = [departure, arrival]
        onSearchFlights({ ...newState })
    }
    const departure = filter.legs[0]
    const arrival = filter.legs[1]
    return (
        <div className={'round-trip-container'}>
            <div className={'filter-background'}>
                <div className={'title-container'}>
                    <h2>{'Search flights'}</h2>
                    {(departure.from || departure.to) && (
                        <button className={'button-for-reset'} onClick={onResetFlight}>
                            {'CLEAR SEARCH'}
                        </button>
                    )}
                </div>
                <div className={'form-content'}>
                    <div className={'flight-search-box'}>
                        <InputField
                            type={'select'}
                            id={'from'}
                            placeholder={'From where?'}
                            value={departure.from}
                            onChange={onUpdateFilter}
                            isSingleField
                            options={airports}
                            noOptionMessage={() => 'Enter airport name or city.'}
                        />
                        <FontAwesomeIcon icon={faPaperPlane} className={'icon'} />
                        <InputField
                            type={'select'}
                            placeholder={'Where to?'}
                            value={departure.to}
                            id={'to'}
                            onChange={onUpdateFilter}
                            isSingleField
                            options={airports}
                            noOptionMessage={() => 'Enter airport name or city.'}
                        />
                    </div>
                    <div className={'separator'} />
                    <div className={'time-date-box'}>
                        <InputField
                            type={'date'}
                            id={'date'}
                            placeholder={'Departure date?'}
                            value={departure.date}
                            onChange={onUpdateFilter}
                            isSingleField
                        />
                        <InputField
                            type={'time'}
                            id={'time'}
                            placeholder={'Pick time'}
                            value={departure.time}
                            onChange={onUpdateFilter}
                            isSingleField
                        />
                    </div>
                    <InputField
                        type={'number'}
                        id={'numberOfSeats'}
                        placeholder={'Number of seats'}
                        value={filter.numberOfSeats || 1}
                        onChange={onUpdateFilter}
                        maxValue={20}
                        isSingleField
                    />
                    <div className={'separator'} />
                    <div className={'footer-container'}>
                        <ActionButton onClick={onSubmitSearch} title={'Search'} />
                    </div>
                </div>
                <h2 className={'mt-10'}>{'Return flight'}</h2>
                <div className={'form-content'}>
                    <div className={'flight-search-box'}>
                        <InputField
                            type={'text'}
                            id={'returnFrom'}
                            placeholder={'From where?'}
                            onChange={onUpdateFilter}
                            isSingleField
                            options={airports}
                            readOnly
                            value={arrival.from ? arrival.from.label : ''}
                            noOptionMessage={() => 'Enter airport name or city.'}
                        />
                        <FontAwesomeIcon icon={faPaperPlane} className={'icon'} />
                        <InputField
                            placeholder={'Where to?'}
                            value={arrival.to ? arrival.to.label : ''}
                            id={'returnTo'}
                            type={'text'}
                            readOnly
                            onChange={onUpdateFilter}
                            isSingleField
                            options={airports}
                            noOptionMessage={() => 'Enter airport name or city.'}
                        />
                    </div>
                    <div className={'separator'} />
                    <div className={'time-date-box'}>
                        <InputField
                            type={'date'}
                            id={'returnDate'}
                            placeholder={'Departure date?'}
                            value={arrival.date}
                            onChange={onUpdateFilter}
                            isSingleField
                        />
                        <InputField
                            type={'time'}
                            id={'returnTime'}
                            placeholder={'Pick time'}
                            value={arrival.time}
                            onChange={onUpdateFilter}
                            isSingleField
                        />
                    </div>
                    <div className={'separator'} />
                    <InputField type={'hidden'} id={'numberOfSeats'} />
                </div>
                {/* <div className={'form-content padding-footer'}> */}
                {/*    <div className={'footer-container'}> */}
                {/*        <ActionButton onClick={onSubmitSearch} title={'Search'} /> */}
                {/*    </div> */}
                {/* </div> */}
            </div>
        </div>
    )
}

export default RoundTripSection
