export const UPDATE_LOGIN_FORM = 'UPDATE_LOGIN_FORM'
export const FORM_SUBMITTED = 'FORM_SUBMITTED'
export const INVALID_FORM_SUBMITTED = 'INVALID_FORM_SUBMITTED'
export const API_LOGIN_REQUEST = 'API_LOGIN_REQUEST'
export const API_LOGIN_REQUEST_SUCCESS = 'API_LOGIN_REQUEST_SUCCESS'
export const API_LOGIN_REQUEST_ERROR = 'API_LOGIN_REQUEST_ERROR'
export const API_PROFILE_REQUEST = 'API_PROFILE_REQUEST'
export const API_PROFILE_REQUEST_SUCCESS = 'API_PROFILE_REQUEST_SUCCESS'
export const API_FORGOT_PASSWORD_REQUEST = 'API_FORGOT_PASSWORD_REQUEST'
export const API_FORGOT_PASSWORD_SUCCESS = 'API_FORGOT_PASSWORD_SUCCESS'
export const API_RESET_PASSWORD_REQUEST = 'API_RESET_PASSWORD_REQUEST'
export const API_RESET_PASSWORD_SUCCESS = 'API_RESET_PASSWORD_SUCCESS'
export const LOGOUT = 'LOGOUT'
export const REDIRECT = 'REDIRECT'
