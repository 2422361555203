import React from 'react'

const MessageBox = ({ message = '', status = 'success' }) => {
    return (
        <div className={`message-box status-${status}`}>
            <p>{message}</p>
        </div>
    )
}

export default MessageBox
