import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Header from '../../components/organisms/header'

import QuickDetails from './components/quickDetails/QuickDetails'
import ClientCardContainer from './components/clients/clientCardContainer'
import DiagramContainer from './components/diagram/DiagramContainer'
import { actions } from './duck'
import { actions as clientActions } from '../clients/duck'
import { actions as orderActions } from '../order/duck'

class DashboardScene extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: '24 h',
            activeCard: 'Total revenue'
        }
    }

    componentDidMount() {
        const { loadOrders, loadProfile, loadClients } = this.props
        loadOrders()
        loadProfile()
        loadClients()
    }

    onHandlerTabsInQuickCard = (activeTab) => {
        this.setState({ activeTab })
    }

    onHandleCardsInDiagramContainer = (activeCard) => {
        this.setState({ activeCard })
    }

    render() {
        const { activeTab, activeCard } = this.state
        const { upcoming = [], previous = [], clients } = this.props
        const orders = upcoming.concat(previous)
        return (
            <div>
                <Header activePage={this.title} />
                <div className={'container'}>
                    <div className={'container-dashboard'}>
                        <div className={'card-container-dashboard'}>
                            <QuickDetails
                                activeTab={activeTab}
                                onHandlerTabsInQuickCard={this.onHandlerTabsInQuickCard}
                                orders={orders}
                            />
                            <ClientCardContainer clients={clients} />
                        </div>
                        <div>
                            <DiagramContainer
                                activeCard={activeCard}
                                onHandleCardsInDiagramContainer={
                                    this.onHandleCardsInDiagramContainer
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ orders, dashboard, client }) => {
    return { ...orders, ...dashboard, ...client }
}

export default connect(mapStateToProps, {
    ...orderActions,
    ...actions,
    ...clientActions
})(withRouter(DashboardScene))
