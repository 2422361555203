import { API_LOGIN_REQUEST, UPDATE_LOGIN_FORM } from './authTypes'

export const onUpdateField = (field, value) => (dispatch) => {
    const object = { shouldValidate: false }
    object[field] = value

    dispatch({
        type: UPDATE_LOGIN_FORM,
        payload: object
    })
}

export const submitLoginForm = (email, password, rememberMe, history) => (dispatch) => {
    dispatch({
        type: API_LOGIN_REQUEST,
        payload: { email, password, rememberMe, history }
    })
}
