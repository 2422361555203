import * as React from 'react'
import { Link } from 'react-router-dom'
import { HOME_ICON_WHITE } from '../../../assets/assets'

const NotificationItem = ({ icon = HOME_ICON_WHITE, menu = [] }) => {
    return (
        <div className={'notification-item'}>
            <Link to={'/dashboard'}>
                <img src={icon} alt={'Dashboard'} />
                {menu.length > 0 && <span className={'notif-counter'}>{menu.length}</span>}
            </Link>
            <ul className={'menu-drop-down'}>
                {menu.length > 0 &&
                    menu.map((menuItem) => (
                        <li>
                            <a href={menuItem.link}>{menuItem.text}</a>
                        </li>
                    ))}
            </ul>
        </div>
    )
}

export default NotificationItem
