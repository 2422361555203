import React from 'react'
import moment from 'moment'
import TruncateString from 'react-truncate-string'
import ActionButton from '../../../components/atoms/buttons/actionButton'
import CurrencyAmount from '../../../components/atoms/text/currencyPrice'
import { dateAddHours } from '../../../utilities/dateUtility'

const FlightItem = ({ flight = {}, onPress = () => null }) => {
    const { ac, flightTimeStr, legs } = flight
    const departure = legs[0]
    return (
        <div className={'flight-container'}>
            <div className={'flight-item'}>
                {ac.images && ac.images.length > 0 && (
                    <div className={'flight-image-container'}>
                        <img src={ac.images[0]} alt={ac.title} />
                    </div>
                )}
                <div className={'item-header'}>
                    <p className={'header-title'}>
                        {ac.title}
                        <span className={'lower-text'}>
                            {ac.pax} {'seats'}
                        </span>
                    </p>
                    <CurrencyAmount amount={flight.price} currency={ac.currency} />
                </div>
                <div className={'flight-body'}>
                    <div className={'departure'}>
                        <div className={'city-label'} title={departure.airportFrom.city}>
                            <TruncateString text={departure.airportFrom.city} truncateAt={30} />
                        </div>
                        <p className={'location-lbl'}>{departure.airportFrom.icao}</p>
                        <p className={'dateLbl'}>
                            <strong>{moment(departure.fromDate).format('MMM DD')}</strong>
                            {moment(departure.fromDate).format('LT')}
                        </p>
                    </div>
                    <div className={'arrival'}>
                        <div className={'city-label'}>
                            <TruncateString text={departure.airportTo.city} truncateAt={17} />
                        </div>
                        <p className={'location-lbl'}>{departure.airportTo.icao}</p>
                        <p className={'dateLbl'}>
                            <strong>
                                {moment(
                                    dateAddHours(departure.fromDate, flight.flightTimeDec)
                                ).format('MMM DD')}
                            </strong>
                            {moment(dateAddHours(departure.fromDate, flight.flightTimeDec)).format(
                                'LT'
                            )}
                        </p>
                    </div>
                </div>
                <div className={'duration'}>
                    <p>
                        {'Duration: '}
                        <strong>{flightTimeStr}</strong>
                    </p>
                </div>
                <ActionButton
                    title={'Request flight'}
                    className={'secondary'}
                    onClick={() => onPress(flight)}
                />
            </div>
        </div>
    )
}

export default FlightItem
