import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const ClientItem = ({ client, onDelete = () => null }) => {
    return (
        <div className={'selected-client-item'}>
            <p>
                {`${client.user.firstName} ${client.user.lastName}`}
                <span>{client.user.email}</span>
            </p>
            <button title={'Remove client'} onClick={() => onDelete(client)}>
                <FontAwesomeIcon icon={faTrash} />
            </button>
        </div>
    )
}

export default ClientItem
