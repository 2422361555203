import { API_PROFILE_REQUEST } from '../../auth/duck/authTypes'
import { API_UPDATE_PROFILE_FORM_REQUEST, UPDATE_PROFILE_FORM } from './dashTypes'

export const loadProfile = () => ({
    type: API_PROFILE_REQUEST
})

export const updateProfile = (field) => {
    return {
        type: UPDATE_PROFILE_FORM,
        payload: field
    }
}

export const storeProfile = (profile) => ({
    type: API_UPDATE_PROFILE_FORM_REQUEST,
    payload: profile
})
