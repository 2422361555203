// import {} from './types'

import {
    API_LOAD_ORDER_DETAILS_REQUEST_SUCCESS,
    API_LOAD_ORDERS_REQUEST_SUCCESS,
    SET_ACTIVE_ORDER,
    SET_ACTIVE_ORDER_CLIENT,
    API_LOAD_OFFERS_REQUEST_SUCCESS,
    API_STORE_OFFER_REQUEST_SUCCESS,
    API_GET_OFFER_REQUEST_SUCCESS
    // eslint-disable-next-line import/named
} from './types'

export const initialState = {
    orders: [],
    upcoming: [],
    previous: [],
    isLoading: false,
    activeOrder: null,
    offers: [],
    isCompleted: false,
    offer: null
}

const reducer = (state = initialState, action) => {
    switch (action.type && action.type) {
        case API_LOAD_ORDERS_REQUEST_SUCCESS:
            return { ...state, ...action.payload }
        case SET_ACTIVE_ORDER:
            return { ...state, activeOrder: { ...action.payload } }
        case SET_ACTIVE_ORDER_CLIENT:
            return { ...state, activeOrder: { ...action.payload } }
        case API_LOAD_ORDER_DETAILS_REQUEST_SUCCESS:
            return { ...state, activeOrder: action.payload }
        case API_LOAD_OFFERS_REQUEST_SUCCESS:
            return { ...state, offers: [...action.payload] }
        case API_STORE_OFFER_REQUEST_SUCCESS:
            return { ...state, offers: { ...action.payload }, isCompleted: true }
        case API_GET_OFFER_REQUEST_SUCCESS:
            return { ...state, offer: { ...action.payload } }
        default:
            return state
    }
}

export default reducer
