import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { confirmAlert } from 'react-confirm-alert'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import Header from '../../components/organisms/header'
import TabBarTitle from '../../components/atoms/buttons/tabBarTitle'
import OneWaySection from './components/oneWaySection'
import { actions } from './duck'
import RoundTripSection from './components/roundTripSection'
import MultiLegSection from './components/multiLegSection'
import { activeFilter, isFilterValid } from './duck/selectors'
import FlightItem from './components/flightItem'
import { flightID } from '../../utilities/flightUtitlity'

class DestinationsScene extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: 'One way',
            searchStatus: 'empty'
        }
    }

    statusMessage = () => {
        const { searchStatus } = this.state
        switch (searchStatus) {
            case 'loading':
                return 'Loading flights...'
            default:
                return (
                    <span>
                        {'Search for flights by selecting '}
                        <br />
                        <strong>{'destination (Where?)'}</strong>
                        {' &'} <strong>{'departure (From?)'}</strong>
                        {', '}
                        {'location and departure date...'}
                    </span>
                )
        }
    }

    onChangeTab = (activeTab) => {
        this.setState({
            activeTab
        })
    }

    onUpdateSearch = (key, value) => {
        const { fields, fieldValues } = this.state
        const index = fields.indexOf(key)
        const values = [...fieldValues]
        values[index] = value

        this.setState({ fieldValues: values })
    }

    onUpdateFilter = (filterType, filter) => {
        const { updateFlightsFilter } = this.props
        switch (filterType) {
            case 'oneWayFilter':
                updateFlightsFilter({ oneWayFilter: filter })
                break
            case 'roundTripFilter':
                updateFlightsFilter({ roundTripFilter: filter })
                break
            case 'multiLegFilter':
                updateFlightsFilter({ multiLegFilter: filter })
                break
            default:
                break
        }
    }

    onAirportSearch = (type, value, index) => {
        const { searchForAirport } = this.props
        searchForAirport(type, value, index)
    }

    onSearchFlights = () => {
        const { oneWayFilter, roundTripFilter, multiLegFilter } = this.props
        const { activeTab } = this.state
        let filters = []
        let paxNum = 1
        switch (activeTab) {
            case 'One way':
                if (!isFilterValid(oneWayFilter)) {
                    toast('Incomplete flight filter', { type: 'error' })
                    return
                }
                paxNum = oneWayFilter.numberOfSeats
                filters.push({ ...oneWayFilter })
                break
            case 'Round trip':
                if (!isFilterValid(roundTripFilter)) {
                    toast('Incomplete flight filter', { type: 'error' })
                    return
                }
                paxNum = roundTripFilter.numberOfSeats
                filters = [...roundTripFilter.legs]
                break
            case 'Multi leg':
                if (!isFilterValid(multiLegFilter)) {
                    toast('Incomplete flight filter', { type: 'error' })
                }
                filters = [...multiLegFilter.legs]
                paxNum = multiLegFilter.numberOfSeats
                break
            default:
                break
        }
        const { searchFlights } = this.props
        const query = filters.map((item) => ({
            fromICAO: item.from.value.icao,
            toICAO: item.to.value.icao,
            fromDate: `${moment(item.date).format('MM/DD/YYYY')} ${item.time}`
        }))
        searchFlights(query, paxNum, activeTab)
    }

    onBookFlight = (flight) => {
        const { legs } = flight
        const departure = legs[0]

        confirmAlert({
            title: 'Confirm to complete booking',
            message: `Confirm booking for flight ${departure.airportFrom.icao} - ${departure.airportTo.icao}`,
            buttons: [
                {
                    label: 'Yes, request the flight',
                    onClick: () => {
                        // toast(
                        //     `Your flight ${departure.airportFrom.icao} - ${departure.airportTo.icao} is getting booked...`,
                        //     { type: 'info' }
                        // )
                        const { bookFlight } = this.props
                        const { activeTab } = this.state
                        const { numberOfSeats } = activeFilter(activeTab, this.props)

                        bookFlight({ quote: flight, quoteID: flightID(flight), pax: numberOfSeats })
                    }
                },
                {
                    label: 'Cancel'
                }
            ]
        })
    }

    showBookSuccess = () => {
        const { showOrderSuccess } = this.props

        return (
            showOrderSuccess &&
            confirmAlert({
                title: 'Booking completed',
                message: `Your flights will be listed under Orders tabs`,
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => {
                            const { updateFlightsFilter, resetFlights } = this.props
                            updateFlightsFilter({ showOrderSuccess: false })
                            resetFlights()
                        }
                    },
                    {
                        label: 'Open orders',
                        onClick: () => {
                            const { history, updateFlightsFilter, resetFlights } = this.props
                            history.push('/orders')
                            updateFlightsFilter({ showOrderSuccess: false })
                            resetFlights()
                        }
                    }
                ]
            })
        )
    }

    render() {
        const { activeTab, searchStatus } = this.state
        const {
            oneWayFilter,
            filterAirports,
            quotes,
            roundTripFilter,
            multiLegFilter,
            onAddLeg,
            resetFlights
        } = this.props

        return (
            <div>
                <Header activePage={'Aircrafts'} />
                <div className={'container'}>
                    <TabBarTitle
                        tabs={['One way', 'Round trip', 'Multi leg']}
                        activeTab={activeTab}
                        onChange={this.onChangeTab}
                    />
                    <div className={'flights-container'}>
                        {activeTab === 'One way' && (
                            <OneWaySection
                                airports={filterAirports}
                                filter={oneWayFilter}
                                onFilterUpdated={this.onUpdateFilter}
                                onSearchForAirports={this.onAirportSearch}
                                onSearchFlights={this.onSearchFlights}
                                onResetFlight={resetFlights}
                            />
                        )}
                        {activeTab === 'Round trip' && (
                            <RoundTripSection
                                airports={filterAirports}
                                filter={roundTripFilter}
                                onFilterUpdated={this.onUpdateFilter}
                                onSearchForAirports={this.onAirportSearch}
                                onSearchFlights={this.onSearchFlights}
                                onResetFlight={resetFlights}
                            />
                        )}
                        {activeTab === 'Multi leg' && (
                            <MultiLegSection
                                airports={filterAirports}
                                filter={multiLegFilter}
                                onFilterUpdated={this.onUpdateFilter}
                                onSearchFlights={this.onSearchFlights}
                                onAddLeg={onAddLeg}
                                onSearchForAirports={this.onAirportSearch}
                                onResetFlight={resetFlights}
                            />
                        )}
                        <div className={'flight-search-container'}>
                            {quotes.length < 1 ? (
                                <p className={'empty-result-label'}>
                                    <span className={'status-icon'}>
                                        <FontAwesomeIcon icon={faPaperPlane} />
                                    </span>
                                    {this.statusMessage(searchStatus)}
                                </p>
                            ) : (
                                quotes.map((flight) => (
                                    <FlightItem
                                        flight={flight}
                                        key={flight.id}
                                        onPress={this.onBookFlight}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                </div>
                {this.showBookSuccess()}
            </div>
        )
    }
}

const mapStateToProps = ({ destinations, dashboard }) => {
    return { ...destinations, dashboard }
}

export default connect(mapStateToProps, {
    ...actions
})(withRouter(DestinationsScene))
