import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import InputField from '../../../components/atoms/form/inputField'
import CheckBoxField from '../../../components/atoms/form/checkBoxField'
import PageTitle from '../../../components/atoms/text/pageTitle'
import ActionButton from '../../../components/atoms/buttons/actionButton'
import Title from '../../../components/atoms/text/title/title'
import * as actions from '../duck/authActions'
import { isFieldValid, isFormFilled } from '../duck/selectors'
import MessageBox from '../../../components/atoms/text/messageBox'

class LoginScene extends React.Component {
    componentDidMount() {
        const { onUpdateField, isLoading } = this.props
        if (isLoading === true) {
            onUpdateField('isLoading', false)
        }
    }

    componentDidUpdate = () => {
        const { redirectTo, history } = this.props

        if (redirectTo === true) {
            setTimeout(() => {
                history.push('/dashboard')
            }, 500)
        }
    }

    onSignIn = () => {
        const {
            submitLoginForm,
            email,
            password,
            termsAccepted,
            rememberMe,
            onUpdateField,
            history
        } = this.props
        onUpdateField('isLoading', true)
        if (isFormFilled(email, password, termsAccepted)) {
            if (submitLoginForm) {
                submitLoginForm(email, password, rememberMe, history)
            }
            return
        }
        onUpdateField('shouldValidate', true)
        const fields = { email, password, termsAccepted }

        const errors = Object.keys(fields)
            // eslint-disable-next-line array-callback-return,consistent-return
            .map((item, i) => {
                const key = Object.keys(fields)[i]
                const value = fields[key]
                if (!isFieldValid(key, value)) {
                    return key
                }
            })
            .filter((item) => item)
        onUpdateField('errors', errors)
        onUpdateField('isLoading', false)
        onUpdateField('shouldValidate', true)
    }

    render() {
        const {
            rememberMe,
            password,
            email,
            onUpdateField,
            errors = [],
            shouldValidate,
            errorMessage,
            isLoading
        } = this.props

        return (
            <div className={'login-page'}>
                <div className={'login-section login-image'}>
                    <span>{'Copyright &copy; 2020. EncoreJets'}</span>
                </div>
                <div className={'login-section'}>
                    <div className={'login-section-form'}>
                        <PageTitle title={'Login'} />
                        <Title title={'Login'} />
                        <div className={'notification-box'}>
                            {shouldValidate && errors.length >= 0 && (
                                <MessageBox
                                    status={'error'}
                                    message={
                                        typeof errorMessage === 'string'
                                            ? errorMessage
                                            : String(errorMessage)
                                    }
                                />
                            )}
                        </div>
                        <InputField
                            id={'email'}
                            placeholder={'E-mail'}
                            value={email}
                            onChange={onUpdateField}
                            isInvalid={shouldValidate && errors.includes('email')}
                        />
                        <InputField
                            id={'password'}
                            placeholder={'Password'}
                            type={'password'}
                            value={password}
                            onChange={onUpdateField}
                            isInvalid={shouldValidate && errors.includes('password')}
                        />
                        <CheckBoxField
                            id={'rememberMe'}
                            labelText={'Remember me'}
                            isChecked={rememberMe}
                            onChange={onUpdateField}
                        />
                        <Link to={'/auth/reset-password'}>{'Forgot password'}</Link>
                        <div className={'full-width'}>
                            <ActionButton
                                title={isLoading ? 'Loading...' : 'Login'}
                                onClick={this.onSignIn}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { auth } = state
    return auth
}

export default connect(mapStateToProps, {
    ...actions
})(withRouter(LoginScene))
