import React from 'react'
import { toast } from 'react-toastify'
import { Redirect } from 'react-router-dom'
import { remove } from '../services/helpers/storageHelper'

export const logout = () => {
    remove('auth')
    remove('token')
    setTimeout(() => {
        return <Redirect to={'/auth/login'} />
    }, 500)
}

export const shouldLogout = ({ message }) => {
    if (message) {
        if (message.includes('Unauthenticated')) {
            // console.log('error', error.message)
            toast(message, { type: 'error' })
            logout()
        }
    }
}
