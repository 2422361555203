import AuthApiMiddleware from './AuthApiMiddleware'
import ClientApiMiddleware from './ClientApiMiddleware'
import OrderApiMiddleware from './OrderApiMiddleware'
import DestinationMiddleware from './DestinationApiMiddleware'
import AirportApiMiddleware from './AiportApiMiddleware'
import FlightSearchApiMiddleware from './FlightSearchApiMiddleware'
import QuoteApiMiddleware from './QuoteApiMiddleware'

export default {
    AuthApiMiddleware,
    ClientApiMiddleware,
    OrderApiMiddleware,
    DestinationMiddleware,
    AirportApiMiddleware,
    FlightSearchApiMiddleware,
    QuoteApiMiddleware
}
