// import { objectFromStorage } from '../services/helpers/storageHelper'

export const clientInfo = (client) => {
    const { user } = client

    if (!user) {
        return 'No client data'
    }
    return `${user.firstName ? user.firstName || user.email : ''} ${
        user.lastName ? user.lastName : ''
    }`
}

export const clientAvatar = (client) => {
    return client.user.photo || null
}

export const orderClientInfo = (client) => {
    if (!client) {
        return 'No client data'
    }
    return client.name
}
