import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import PageTitle from '../../components/atoms/text/pageTitle'
import Header from '../../components/organisms/header'
import ProfileDetails from './components/profileDetails'
import * as actions from '../dashboard/duck/dashActions'

class ProfileScene extends Component {
    componentDidMount = () => {
        const { loadProfile } = this.props
        loadProfile()
    }

    onSave = () => {
        const { storeProfile, profile } = this.props
        const { user } = profile
        storeProfile(user)
    }

    onUpdateProfile = (key, value) => {
        const { updateProfile, profile } = this.props
        const { user } = profile || {}
        user[key] = value
        updateProfile({ ...profile, user: { ...user } })
    }

    render() {
        const { profile } = this.props
        return (
            <div>
                <PageTitle title={'Client details'} />
                <Header activePage={'Clients'} />
                <div className={'container container-new-client'}>
                    <ProfileDetails
                        profile={profile}
                        onSave={this.onSave}
                        onUpdateProfile={this.onUpdateProfile}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ dashboard }) => {
    return { ...dashboard }
}

export default connect(mapStateToProps, {
    ...actions
})(withRouter(ProfileScene))
