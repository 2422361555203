import React from 'react'
import Header from '../../components/organisms/header'
import Title from '../../components/atoms/text/title/title'
import { MARKETING_IMG } from '../../assets/assets'
// import Header from '../../../components/organisms/header'

class MarketingScene extends React.PureComponent {
    render() {
        return (
            <div>
                <Header activePage={'Marketing tool'} />
                <div className={'container'}>
                    <div className={'columns'}>
                        <div className={'column marketing-intro'}>
                            <div>
                                <Title title={'Welcome to new generation of marketing tool'} />
                                <p>
                                    {
                                        'Our tool is using last technology which helps you enrich your business. Choose best suite template and start creating!'
                                    }
                                </p>
                            </div>
                        </div>
                        <div className={'column marketing-illustration'}>
                            <img src={MARKETING_IMG} alt={'EncoreJets Marketing'} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MarketingScene
