import {
    API_FLY_EMPTY_INSTANT_QUOTE_SUCCESS,
    API_LOAD_DESTINATIONS_REQUEST_SUCCESS,
    AVIND_AIRPORT_ARRIV_SUCCESS,
    AVIND_AIRPORT_DEPART_SUCCESS,
    UPDATE_DESTINATIONS_AIRPORT,
    UPDATE_DESTINATIONS_FILTER_TYPE,
    FLIGHT_BOOK_REQUEST_SUCCESS,
    UPDATE_ORDER_STATUS,
    ADD_NEW_LEG,
    CLEAR_FLIGHT_SEARCH
} from './types'
import { minDate } from '../../../utilities/dateUtility'

export const initialState = {
    // destinations: [],
    isLoading: false,
    oneWayFilter: { from: null, to: null, date: minDate(2), time: '10:00', numberOfSeats: 1 },
    roundTripFilter: {
        numberOfSeats: 2,
        legs: [
            { from: null, to: null, date: minDate(2), time: '11:00' },
            { from: null, to: null, date: minDate(2), time: '11:00' }
        ]
    },
    multiLegFilter: {
        numberOfSeats: 1,
        legs: [{ from: null, to: null, date: minDate(2), time: '11:00' }]
    },
    filterAirports: [],
    activeField: 'arrival',
    quotes: [],
    showOrderSuccess: false
}

const reducer = (state = initialState, action) => {
    switch (action.type && action.type) {
        case API_LOAD_DESTINATIONS_REQUEST_SUCCESS:
            return { ...state, destinations: action.payload.destinations }
        case UPDATE_DESTINATIONS_FILTER_TYPE:
            switch (Object.keys(action.payload)[0]) {
                case 'oneWayFilter':
                    try {
                        const { roundTripFilter } = state
                        const { oneWayFilter } = action.payload
                        roundTripFilter.legs[0] = { ...oneWayFilter }
                        const returnFlight = { ...oneWayFilter }
                        returnFlight.to = oneWayFilter.from
                        returnFlight.from = oneWayFilter.to
                        roundTripFilter.legs[1] = returnFlight
                        const { multiLegFilter } = state
                        multiLegFilter.legs = roundTripFilter.legs
                        return { ...state, ...action.payload, roundTripFilter, multiLegFilter }
                    } catch (e) {
                        return { ...state }
                    }
                case 'roundTripFilter':
                    try {
                        const { roundTripFilter } = action.payload
                        const oneWayFilter = roundTripFilter.legs[0]
                        const { multiLegFilter } = state
                        multiLegFilter.legs = roundTripFilter.legs
                        return { ...state, ...action.payload, oneWayFilter, multiLegFilter }
                    } catch (e) {
                        return { ...state }
                    }
                case 'multiLegFilter':
                    try {
                        const { oneWayFilter, roundTripFilter } = state
                        const { multiLegFilter } = action.payload
                        return {
                            ...state,
                            oneWayFilter,
                            roundTripFilter,
                            multiLegFilter
                        }
                    } catch {
                        return { ...state }
                    }
                default:
                    break
            }
            return { ...state, ...action.payload }
        case FLIGHT_BOOK_REQUEST_SUCCESS:
            return { ...state, showOrderSucces: true }
        case AVIND_AIRPORT_ARRIV_SUCCESS:
            return {
                ...state,
                filterAirports: [...action.payload.airports],
                activeField: 'departure'
            }
        case AVIND_AIRPORT_DEPART_SUCCESS:
            return { ...state, filterAirports: action.payload.airports, activeField: 'arrival' }
        case UPDATE_DESTINATIONS_AIRPORT:
            return { ...action.payload }
        case API_FLY_EMPTY_INSTANT_QUOTE_SUCCESS:
            return { ...state, quotes: [...action.payload] }

        case UPDATE_ORDER_STATUS:
            return { ...state, ...action.payload }

        case ADD_NEW_LEG:
            try {
                const { multiLegFilter } = state
                const newLeg = multiLegFilter.legs[multiLegFilter.legs.length - 1]
                // const newMultiLeg = { ...multiLegFilter, legs: [...multiLegFilter.legs, newLeg] }
                return {
                    ...state,
                    multiLegFilter: { ...multiLegFilter, legs: [...multiLegFilter.legs, newLeg] }
                }
            } catch {
                return { ...state }
            }

        case CLEAR_FLIGHT_SEARCH:
            return { ...initialState, quotes: [], filterAirports: [] }
        default:
            return state
    }
}

export default reducer
