import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { objectFromStorage } from '../../services/helpers/storageHelper'

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            const { authenticationToken = null } = objectFromStorage('auth') || {}
            const token = objectFromStorage('token')

            return authenticationToken !== null || token !== null ? (
                <div>
                    <Component {...props} />
                </div>
            ) : (
                <div>
                    <Redirect
                        to={{
                            pathname: '/auth/login',
                            state: { from: props.location }
                        }}
                    />
                </div>
            )
        }}
    />
)
export default PrivateRoute
