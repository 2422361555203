import {
    API_LOGIN_REQUEST_ERROR,
    API_LOGIN_REQUEST_SUCCESS,
    LOGOUT,
    UPDATE_LOGIN_FORM
} from './authTypes'

export const initialState = {
    email: window.location.hostname === 'localhost' ? 'broker@encorejets.com' : '',
    password: window.location.hostname === 'localhost' ? '123123123' : '',
    user: null,
    isLoading: false,
    shouldValidate: false,
    termsAccepted: true,
    rememberMe: false,
    errors: [],
    errorMessage: 'Please complete missing details.',
    token: null,
    rememberToken: null,
    redirectTo: false,
    resetEmail: null
}

const reducer = (state = initialState, action) => {
    switch (action.type && action.type) {
        case UPDATE_LOGIN_FORM:
            return { ...state, ...action.payload }
        case API_LOGIN_REQUEST_ERROR:
            return { ...state, ...action.payload }
        case API_LOGIN_REQUEST_SUCCESS:
            return { ...state, ...action.payload }
        case LOGOUT:
            return { ...state, redirectTo: false }
        default:
            return state
    }
}

export default reducer
