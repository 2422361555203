import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { flightDepartureDateTime, flightDestinations } from '../../../utilities/flightUtitlity'
import { clientInfo, orderClientInfo } from '../../../utilities/clientUtility'
import InputField from '../../../components/atoms/form/inputField'
import ActionButton from '../../../components/atoms/buttons/actionButton'

const AddOrderClient = ({
    onClose = () => null,
    order = {},
    allClients = [],
    onSetClient = () => null
}) => {
    const clients = (order && order.clients) || []
    const [selectedClient, setSelectedClient] = useState(null)
    return (
        <div className={'order-client-container'}>
            <div className={'order-client-content'}>
                <div className={'client-content'}>
                    <h1>{'Add client(s) to order'}</h1>
                    <h2>{'Order details'}</h2>
                    <p>
                        {order &&
                            `${flightDestinations(order.quote)} - ${flightDepartureDateTime(
                                order.quote
                            )}`}
                    </p>
                    <h2>{'Clients'}</h2>
                    {clients.map((client) => (
                        <p key={client.id}>{orderClientInfo(client)}</p>
                    ))}
                    {clients.length === 0 && <p>{'No added clients.'}</p>}
                    <h2>{'Add client'}</h2>
                    <div className={'add-control'}>
                        <InputField
                            type={'select'}
                            placeholder={'Select client'}
                            // value={filter.to}
                            id={'clients'}
                            onChange={(_, value) => {
                                if (value) {
                                    setSelectedClient(value)
                                }
                            }}
                            options={allClients.map((client) => ({
                                // eslint-disable-next-line no-underscore-dangle
                                value: client._id,
                                label: clientInfo(client)
                            }))}
                        />
                        <ActionButton
                            className={'add-client-button'}
                            title={'Add'}
                            onClick={() => onSetClient(order, selectedClient)}
                        />
                    </div>
                </div>
                <div>
                    <button className={'close-button'} onClick={() => onClose(null)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AddOrderClient
