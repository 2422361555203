import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Header from '../../../components/organisms/header'
import Title from '../../../components/atoms/text/title/title'
import TabBarTitle from '../../../components/atoms/buttons/tabBarTitle'
// import SearchForm from '../../../components/organisms/searchForm'
// import Cards from '../../../components/atoms/card/cards'
import * as authActions from '../../auth/duck/authActions'
import * as actions from '../duck/actions'
import OfferItem from '../components/offerItem'

class OffersScene extends React.Component {
    componentDidMount() {
        const { loadOffers } = this.props
        loadOffers()
    }

    onChangeTab = (tab) => {
        const { history } = this.props
        if (tab !== 'Sent quotes') {
            setTimeout(() => {
                history.push(`/orders?tab=${tab}`)
            }, 100)
        }
    }

    render() {
        // const { fields, fieldValues, activeCard, activeTab } = this.state
        const { offers = [] } = this.props
        // const { pricePerPerson } = this.state
        return (
            <div className={'orders-page'}>
                {/* {activeOrder && ( */}
                {/*    <AddOrderClient */}
                {/*        order={activeOrder} */}
                {/*        onClose={setActiveOrder} */}
                {/*        allClients={allClients} */}
                {/*        onSetClient={this.onSetClient} */}
                {/*    /> */}
                {/* )} */}
                <Header activePage={'Orders'} />
                <div className={'container'}>
                    <Title title={'Orders'} />
                    <TabBarTitle
                        tabs={['Previous', 'Upcoming', 'Sent quotes']}
                        activeTab={'Sent quotes'}
                        onChange={this.onChangeTab}
                    />
                    {/* <SearchForm */}
                    {/*    // fields={fields} */}
                    {/*    // values={fieldValues} */}
                    {/*    onChange={this.onUpdateSearch} */}
                    {/*    className={'search-form-field-orders'} */}
                    {/* /> */}
                    {/* <Cards *!/ */}
                    {/*    cards={[ */}
                    {/*        { title: 'Total', price: '10 000' }, */}
                    {/*        { title: 'Active', price: '5000' }, */}
                    {/*        { title: 'Finished', price: '5000' }, */}
                    {/*        { title: 'Total value', price: 'USD 20 000' } */}
                    {/*    ]} */}
                    {/*    className={'card-orders'} */}
                    {/*    // activeCard={activeCard} */}
                    {/*    onChange={this.onHandlerCardInOrders} */}
                    {/* />
                    {/* */}
                    <table className={'orders-list'}>
                        <thead>
                            <tr>
                                {[
                                    'Edit',
                                    'Clients',
                                    'Aircraft',
                                    'Flight',
                                    'Type',
                                    'Pax',
                                    'Duration',
                                    'Price',
                                    'Status'
                                ].map((item) => (
                                    <th
                                        key={item}
                                        className={`left-align ${
                                            item === 'Edit' ? 'edit-order-cell' : ''
                                        }`}>
                                        {item}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {offers.length < 1 && (
                                <tr>
                                    <td colSpan={9}>
                                        <p>{'There is no active quotes yet. '}</p>
                                    </td>
                                </tr>
                            )}
                            {offers.length > 0 &&
                                offers.map((offer) => {
                                    return <OfferItem offer={offer} key={offer._id} />
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ orders, client }) => {
    return { ...orders, allClients: client.clients }
}

export default connect(mapStateToProps, {
    ...actions,
    ...authActions
})(withRouter(OffersScene))
