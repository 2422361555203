import * as React from 'react'

const Avatar = ({ image = null, firstName = '', lastName = '' }) => {
    return (
        <div className={'avatar'}>
            {/* <Link className={'avatar'} onClick={onClick} to={'/auth/logout'}> */}
            {image && <img src={image} alt={`${firstName} ${lastName}`} />}
            {!image && <p>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</p>}
            {/* </Link> */}
        </div>
    )
}

export default Avatar
