import React, { useState } from 'react'
import Title from '../../../../components/atoms/text/title/title'
import InputField from '../../../../components/atoms/form/inputField'
import ActionButton from '../../../../components/atoms/buttons/actionButton'
import { USER_ICON_ACTIVE } from '../../../../assets/assets'

const ClientBasicDetails = ({
    onCancel = () => null,
    onSave = () => null,
    client = {},
    onUpdateClient = () => null
}) => {
    const [profileImage, setProfileImage] = useState('')

    const onUpdateProfileImage = (event) => {
        if (event.target.files && event.target.files[0]) {
            const img = event.target.files[0]
            setProfileImage(URL.createObjectURL(img))
        }
    }
    const user = (client && client.user) || {}

    return (
        <div className={'client-basic-details-container'}>
            <Title title={'Client Details'} />
            {profileImage ? (
                <div className={'container-for-image'}>
                    <img src={profileImage} alt={''} className={'image'} />
                </div>
            ) : (
                <div className={'container-for-image'}>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor={'icon'} className={'icon'}>
                        <img src={user.photo || USER_ICON_ACTIVE} alt={user.email} />
                    </label>
                    {/* <label htmlFor={'button'}> */}
                    {/* <p className={'text-button'}>{'Upload Image'}</p> */}
                    {/* </label> */}
                    <input
                        type={'file'}
                        id={'button'}
                        className={'input-for-images'}
                        onChange={onUpdateProfileImage}
                    />
                </div>
            )}
            <hr className={'hr'} />
            <div className={'input-container client-details-form'}>
                <InputField
                    value={user.firstName}
                    id={'firstName'}
                    label={'First name'}
                    onChange={onUpdateClient}
                    placeholder={'First name'}
                />
                <InputField
                    placeholder={'Last name'}
                    id={'lastName'}
                    value={user.lastName}
                    label={'Last name'}
                    onChange={onUpdateClient}
                />
                <InputField
                    placeholder={'Email'}
                    label={'Email'}
                    id={'email'}
                    value={user.email || ''}
                    onChange={onUpdateClient}
                />
                <InputField
                    placeholder={'Phone'}
                    id={'phone'}
                    value={user.phone || ''}
                    label={'Phone'}
                    onChange={onUpdateClient}
                />
                <InputField
                    placeholder={'State/location'}
                    label={'State/Location'}
                    id={'state'}
                    value={user.state || ''}
                    onChange={onUpdateClient}
                />
                <InputField
                    placeholder={'Birth date'}
                    id={'birthDate'}
                    // type={'date'}
                    label={'Birth date'}
                    value={user.birthDate || ''}
                    onChange={onUpdateClient}
                />
                <InputField
                    placeholder={'Chose status'}
                    id={'status'}
                    label={'Status'}
                    type={'select'}
                    onChange={onUpdateClient}
                    value={{
                        label: user.status || 'Active',
                        value: user.status || 'active'
                    }}
                    options={[
                        { label: 'Active', value: 'active' },
                        { label: 'Invited', value: 'invited' },
                        { label: 'Blocked', value: 'blocked' }
                    ]}
                />
            </div>
            <hr className={'hr'} />
            <div className={'bottom-container'}>
                <div className={'button-container'}>
                    <ActionButton
                        title={'Save'}
                        className={'button-save'}
                        isDark={false}
                        onClick={onSave}
                    />
                    <ActionButton
                        className={'button-cancel'}
                        title={'Cancel'}
                        onClick={onCancel}
                        isDark={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default ClientBasicDetails
