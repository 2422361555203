const envURL = {
    prod: 'https://api.encorejets.com/api/',
    dev: 'http://localhost:8000/api/'
}
export const BASE_URL = window.location.host === 'localhost:3000' ? envURL.dev : envURL.prod

// https://locationiq.com/docs#forward-geocoding

export const MAPS_API = 'bd22667743cdd2'

export const GEOCODING = (lat, long) =>
    `https://us1.locationiq.com/v1/reverse.php?key=${MAPS_API}&lat=${lat}&lon=${long}&format=json`

export const CHASE_URL = 'https://api.chase.com' // 'https://api-sandbox.chase.com'
export const CHASE_CLIENT_ID = '1744783'
export const APP_URI = 'encorejets://'

export const FLY_API = 'https://api.flyeasy.co/apiservice/'
const FLY_TOKEN_TEST = '758653324f0742dc9c078c0410ff4237-701323'
const FLY_TOKEN_PROD = '59e71fb5bbbe51350aea72c615977db2-8207515'
export const FLY_TOKEN = window.location.host === 'localhost' ? FLY_TOKEN_TEST : FLY_TOKEN_PROD
