import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import queryString from 'query-string'

const SearchField = () => {
    const searchText = () => {
        const { search = '' } = queryString.parse(window.location.search)
        return search
    }
    return (
        <form className={'search-field'} method={'get'}>
            {/* eslint-disable-next-line react/button-has-type */}
            <button type={'button'} className={'search-button'}>
                <FontAwesomeIcon icon={faSearch} />
            </button>
            <input
                type={'text'}
                name={'search'}
                defaultValue={searchText()}
                placeholder={'Search'}
            />
        </form>
    )
}

export default SearchField
