import React from 'react'
import TruncateString from 'react-truncate-string'
import moment from 'moment'
import { dateAddHours } from '../../../utilities/dateUtility'

const LegsInfo = ({ quote = {}, legs = [] }) => {
    const legsList = () => {
        if (legs.length === 2 && legs[0].fromDate === legs[1].fromDate) {
            return legs.filter((leg) => !leg.isRepo)
        }
        return legs
    }
    return (
        <div>
            <h3>{'Legs'}</h3>
            {legsList().map((leg) => (
                <div className={'flight-body leg-info'} key={JSON.stringify(leg)}>
                    <div className={'departure'}>
                        <div className={'city-label'}>
                            <TruncateString text={leg.airportFrom.city} truncateAt={17} />
                        </div>
                        <p className={'location-lbl'}>{leg.airportFrom.icao}</p>
                        <p className={'dateLbl'}>
                            <strong>{moment(leg.fromDate).format('MMM DD')}</strong>
                            {moment(leg.fromDate).format('LT')}
                        </p>
                    </div>
                    <div className={'arrival'}>
                        <div className={'city-label'}>
                            <TruncateString text={leg.airportTo.city} truncateAt={17} />
                        </div>
                        <p className={'location-lbl'}>{leg.airportTo.icao}</p>
                        <p className={'dateLbl'}>
                            <strong>
                                {moment(dateAddHours(leg.fromDate, quote.flightTimeDec)).format(
                                    'MMM DD'
                                )}
                            </strong>
                            {moment(dateAddHours(leg.fromDate, quote.flightTimeDec)).format('LT')}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default LegsInfo
