export const isFilterValid = (filter) => {
    return filter.from !== null && filter.to !== null
}

export const areFiltersValid = (filters = []) => {
    let isValid = true
    filters.forEach((filter) => {
        if (!isFilterValid(filter)) {
            isValid = false
        }
    })
    return isValid
}

export const activeFilter = (activeTab, { oneWayFilter, roundTripFilter, multiLegFilter }) => {
    switch (activeTab) {
        case 'One way':
            return oneWayFilter
        case 'Round trip':
            return roundTripFilter
        case 'Multi leg':
            return multiLegFilter

        default:
            return { pax: 1 }
    }
}
