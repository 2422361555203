import React from 'react'
import { Switch } from 'react-router-dom'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { applyMiddleware, createStore } from 'redux'
import rootReducer from '../../reducers'
import { initFonts } from '../../utilities/config'
import PrivateRoute from '../../components/main/PrivateRoute'
import ClientsScene from './list/ClientsScene'

class ClientRoute extends React.Component {
    constructor(props) {
        super(props)

        const enhancer = composeWithDevTools(applyMiddleware(thunk))
        this.store = createStore(rootReducer, enhancer)
    }

    componentDidMount() {
        initFonts()
    }

    render() {
        return (
            <Switch>
                <PrivateRoute path={'/clients'} component={ClientsScene} />
            </Switch>
        )
    }
}

export default ClientRoute
