import React from 'react'

const WeekContainer = ({ items }) => {
    return (
        <div>
            {items.map((item) => {
                return (
                    <div key={item.value} className={'list-item'}>
                        <p className={'title-item'}>{item.title}</p>
                        <p className={'value-item'}>{'1500'}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default WeekContainer
