import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'

export default function Title({ title = '', backLink = null }) {
    return (
        <div className={'title-box'}>
            <h1 className={'title-text'}>
                {title}{' '}
                {backLink && (
                    <a href={backLink}>
                        <FontAwesomeIcon icon={faAngleLeft} className={'icon'} />
                        {'Back'}
                    </a>
                )}
            </h1>
            <div className={'title-border'} />
        </div>
    )
}
