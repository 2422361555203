import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import ActionButton from '../../../components/atoms/buttons/actionButton'
import CurrencyAmount from '../../../components/atoms/text/currencyPrice'
import StatusInfo from '../../../components/atoms/text/statusInfo'
import { flightDepartureDateTime, flightDestinations } from '../../../utilities/flightUtitlity'

const OrderItem = ({
    order = {},
    onSetOrder = () => null,
    onDelete = () => {},
    onViewOrder = () => null,
    shouldDisplayOffer = false
}) => {
    const fields = ['edit', 'clients', 'image', 'flight', 'type', 'pax', 'fit', 'price', 'status']

    const renderClients = () => {
        const { clients = [] } = order.offer || {}
        if (clients.length > 0) {
            return (
                <p>
                    {'Offerred to '}
                    {clients.length}
                    {' clients.'}
                </p>
            )
            // return clients.map((client) => (
            //     // <div>
            //     <p>
            //         {/* <a href={`/client/${client.id}`} className={'action-button'} key={client._id}> */}
            //         {client.user.firstName} {client.user.lastName} {/* // </a> */}
            //     </p>
            //     // </div>
            // ))
        }
        if (!shouldDisplayOffer) {
            return (
                <div>
                    <p className={'missing-info pt1'}>{'No sent quotes.'}</p>
                </div>
            )
        }

        return (
            <div>
                <p className={'missing-info'}>{'No sent quotes.'}</p>
                <ActionButton
                    className={'wire-button'}
                    title={order.isSent ? 'View Quote' : 'Create Quote'}
                    onClick={() => {
                        if (!order.isSent) {
                            onViewOrder(order)
                            return
                        }
                        onSetOrder(order)
                    }}
                    isDark={false}
                />
            </div>
        )
    }

    const renderField = (field) => {
        const { quote } = order
        const { ac } = quote
        switch (field) {
            case 'edit':
                return (
                    order.status === 'pending' && (
                        <div className={'edit-order-buttons'}>
                            <a href={`/order/${order._id}`} title={'Edit client'}>
                                <FontAwesomeIcon icon={faEdit} />
                            </a>
                            <button title={'Delete order'} onClick={() => onDelete(order)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                    )
                )

            case 'clients':
                return renderClients()

            case 'pax':
                return <p style={{ paddingLeft: '1rem' }}>{order.pax}</p>
            case 'type':
                return (
                    <p>
                        {ac.title}
                        <br />
                        {ac.type}
                    </p>
                )
            case 'image':
                return <img src={ac.images[0]} alt={ac.title} />
            case 'price':
                return <CurrencyAmount amount={quote.price} currency={quote.ac.currency} />
            case 'fit':
                return quote.flightTimeStr
            case 'flight':
                return (
                    <p>
                        <strong>{flightDestinations(quote)}</strong> <br />
                        {flightDepartureDateTime(quote)}
                    </p>
                )
            case 'status':
                return <StatusInfo status={order.status || 'pending'} />
            default:
                return <span />
        }
    }

    return (
        <tr>
            {fields.map((field) => (
                <td className={`${field}-cell`} key={field}>
                    {renderField(field)}
                </td>
            ))}
        </tr>
    )
}

export default OrderItem
