import { toast } from 'react-toastify'

import { BASE_URL } from '../config'
import { methodType, defaultHeaders } from '../commonApi'
import {
    API_LOAD_CLIENT_QUOTE_REQUEST,
    API_LOAD_CLIENT_QUOTE_REQUEST_ERROR,
    API_LOAD_CLIENT_QUOTE_REQUEST_SUCCESS
} from '../../../scenes/offerConfirmation/duck/types'

const QuoteApiMiddleware = ({ dispatch }) => (next) => async (action) => {
    switch (action.type) {
        case API_LOAD_CLIENT_QUOTE_REQUEST:
            try {
                const quoteID = action.payload
                const response = await fetch(`${BASE_URL}quote/${quoteID}`, {
                    method: methodType.get,
                    headers: defaultHeaders
                })
                const { offer, message, errors } = await response.json()
                if (errors) {
                    const error = errors.join(' ')
                    toast(errors.join(' '), { type: 'error' })
                    dispatch({
                        type: API_LOAD_CLIENT_QUOTE_REQUEST_ERROR,
                        payload: error
                    })
                    return
                }
                if (response.status > 299 || message) {
                    const error = message || response.statusText
                    toast(error, { type: 'error' })
                    dispatch({
                        type: API_LOAD_CLIENT_QUOTE_REQUEST_ERROR,
                        payload: error
                    })
                }
                dispatch({
                    type: API_LOAD_CLIENT_QUOTE_REQUEST_SUCCESS,
                    payload: offer
                })
            } catch (error) {
                toast(error.message, { type: 'error' })
            }
            break

        default:
            break
    }
    next(action)
}

export default QuoteApiMiddleware
