export const API_LOAD_DESTINATIONS_REQUEST = 'API_LOAD_DESTINATIONS_REQUEST'
export const API_SEARCH_DESTINATIONS_REQUEST = 'API_SEARCH_DESTINATIONS_REQUEST'
export const API_LOAD_DESTINATIONS_REQUEST_SUCCESS = 'API_LOAD_DESTINATIONS_REQUEST_SUCCESS'
export const API_LOAD_DESTINATIONS_REQUEST_ERROR = 'API_LOAD_DESTINATIONS_REQUEST_ERROR'

export const UPDATE_DESTINATIONS_FILTER_TYPE = 'UPDATE_DESTINATIONS_FILTER_TYPE'

export const UPDATE_DESTINATIONS_AIRPORT = 'UPDATE_DESTINATIONS_AIRPORT'
export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS'

export const AVIND_AIRPORT_REQUEST = 'AVIND_AIRPORT_REQUEST'
export const AVIND_AIRPORT_DEPART_SUCCESS = 'AVIND_AIRPORT_DEPART_SUCCESS'
export const AVIND_AIRPORT_ARRIV_SUCCESS = 'AVIND_AIRPORT_ARRIV_SUCCESS'
export const AVIND_AIRPORT_ERROR = 'AVIND_AIRPORT_ERROR'

export const FLIGHT_SEARCH_REQUEST = 'FLIGHT_SEARCH_REQUEST'
export const FLIGHT_SEARCH_REQUEST_SUCCESS = 'FLIGHT_SEARCH_REQUEST_SUCCESS'
export const FLIGHT_SEARCH_REQUEST_ERROR = 'FLIGHT_SEARCH_REQUEST_ERROR'

export const API_FLY_EMPTY_LEGS_SEARCH = 'API_FLY_EMPTY_LEGS_SEARCH'
export const API_FLY_EMPTY_INSTANT_QUOTE = 'API_FLY_EMPTY_INSTANT_QUOTE'
export const API_FLY_EMPTY_INSTANT_QUOTE_SUCCESS = 'API_FLY_EMPTY_INSTANT_QUOTE_SUCCESS'
export const API_FLY_INQUIRY_REQUEST = 'API_FLY_INQUIRY_REQUEST'
export const API_STORE_ORDER = 'API_STORE_ORDER'

export const FLIGHT_BOOK_REQUEST = 'FLIGHT_BOOK_REQUEST'
export const FLIGHT_BOOK_REQUEST_SUCCESS = 'FLIGHT_BOOK_REQUEST_SUCCESS'
export const FLIGHT_BOOK_REQUEST_ERROR = 'FLIGHT_BOOK_REQUEST_ERROR'

export const ADD_NEW_LEG = 'ADD_NEW_LEG'
export const CLEAR_FLIGHT_SEARCH = 'CLEAR_FLIGHT_SEARCH'
