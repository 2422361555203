import React from 'react'

const CheckBoxField = ({
    id = 'id',
    isChecked = false,
    labelText = 'placeholder',
    onChange = () => null,
    isInvalid = false
}) => {
    return (
        <div className={`checkbox-field ${isInvalid && 'error-field'}`}>
            {/* eslint-disable-next-line react/button-has-type,jsx-a11y/control-has-associated-label */}
            <button
                id={id}
                className={`checkbox ${isChecked && 'checked'}`}
                onClick={() => onChange(id, !isChecked)}
            />

            <label htmlFor={id}>{labelText}</label>
        </div>
    )
}

export default CheckBoxField
