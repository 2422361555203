import {
    API_DELETE_ORDER_REQUEST,
    API_GET_OFFER_REQUEST,
    API_LOAD_OFFERS_REQUEST,
    API_LOAD_ORDER_DETAILS_REQUEST,
    API_LOAD_ORDERS_REQUEST,
    API_STORE_OFFER_REQUEST,
    API_UPDATE_ORDER_REQUEST,
    SET_ACTIVE_ORDER,
    SET_ACTIVE_ORDER_CLIENT
} from './types'

export const loadOrders = () => (dispatch) => {
    dispatch({
        type: API_LOAD_ORDERS_REQUEST
    })
}

export const searchOrders = (search) => (dispatch) => {
    dispatch({
        type: API_LOAD_ORDERS_REQUEST,
        payload: { search }
    })
}

export const setActiveOrder = (order) => ({
    type: SET_ACTIVE_ORDER,
    payload: order
})

export const onAddClient = (order) => ({
    type: SET_ACTIVE_ORDER_CLIENT,
    payload: order
})

export const updateOrder = (order) => ({
    type: API_UPDATE_ORDER_REQUEST,
    payload: order
})

export const onDeleteOrder = (orderID) => ({
    type: API_DELETE_ORDER_REQUEST,
    payload: orderID
})

export const loadOrder = (orderID) => ({
    type: API_LOAD_ORDER_DETAILS_REQUEST,
    payload: orderID
})

export const loadOffers = () => ({
    type: API_LOAD_OFFERS_REQUEST
})

export const loadOffer = (id) => ({
    type: API_GET_OFFER_REQUEST,
    payload: { id }
})

export const storeOffer = (offer) => ({
    type: API_STORE_OFFER_REQUEST,
    payload: offer
})
