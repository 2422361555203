import React from 'react'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CheckBoxField from '../atoms/form/checkBoxField'

const SearchForm = ({
    fields = [],
    ids = [],
    values = [],
    onChange = () => null,
    onSubmit = () => null,
    onClear = () => null,
    className = ''
}) => {
    return (
        <div className={'client-search-field'}>
            {fields.map((field, index) => (
                <SearchInputField
                    key={field}
                    onChange={onChange}
                    id={ids[index] || field}
                    index={index}
                    value={values[index]}
                    type={['car transport', 'catering'].includes(field) ? 'checkbox' : 'string'}
                    className={className}
                    onSubmit={onSubmit}
                    onClear={onClear}
                />
            ))}
        </div>
    )
}
export default SearchForm

const SearchInputField = ({
    onChange = () => null,
    onSubmit = () => null,
    onClear = () => null,
    id = '',
    value = '',
    type = 'string',
    index = 0,
    className = ''
}) => {
    return (
        <div className={className}>
            {type === 'string' && (
                <div className={'content'}>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type */}
                    <button
                        className={'search-icon'}
                        type={'submit'}
                        onClick={() => onSubmit({ key: id, value })}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                    <input
                        type={'text'}
                        onChange={(e) => onChange(id, e.target.value, index)}
                        value={value}
                        placeholder={`Search by ${id}`}
                        onKeyDown={(key) => {
                            if (key.keyCode === 13) {
                                onSubmit({ key: id, value })
                            }
                        }}
                    />
                    {value && value.length > 0 && (
                        <button
                            className={'clear-button'}
                            onClick={() => {
                                onClear(id, index)
                            }}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    )}
                </div>
            )}
            {type === 'checkbox' && (
                <div className={'checkbox-content'}>
                    <CheckBoxField
                        labelText={`With ${id}`}
                        id={id}
                        isChecked={value === true}
                        onChange={onChange}
                    />
                </div>
            )}
        </div>
    )
}
