import * as validators from '../../../utilities/validators'

export const isPhoneValid = (phoneNumber) => {
    return phoneNumber && phoneNumber.length > 5
}

export const isFormFilled = (email, password, isTermsAccepted = false) =>
    isTermsAccepted &&
    password &&
    email &&
    password.length > 0 &&
    email.length > 3 &&
    validators.isEmailValid(email)

export const isFieldValid = (field, value) => {
    if (value.length < 3) {
        return false
    }
    switch (field) {
        case 'email':
            return validators.isEmailValid(value)
        case 'termsAccepted':
            return value === true
        case 'password':
            return value.length > 6
        default:
            return false
    }
}
