import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import * as Sentry from '@sentry/react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { initFonts } from '../../utilities/config'

import history from '../../store/history'
import rootReducer from '../../store/rootReducer'
import middlewares from '../../services/api/middlewares'

import DashboardScene from '../../scenes/dashboard/DashboardScene'
import PrivateRoute from './PrivateRoute'
import AuthScene from '../../scenes/auth/AuthScene'
import OrderScene from '../../scenes/order/OrderRoute'
import ClientRoute from '../../scenes/clients/ClientRoute'
import MarketingScene from '../../scenes/marketing/MarketingScene'
import FAAScene from '../../scenes/faa/FaaScene'
import DestinationsScene from '../../scenes/destinations/DestinationsScene'
import WeatherScene from '../../scenes/weather/WeatherScene'
import ClientScene from '../../scenes/clients/clientDetails/ClientScene'
import LoginScene from '../../scenes/auth/login/LoginScene'
import ProfileScene from '../../scenes/profile/ProfileScene'
import ResetPasswordScene from '../../scenes/auth/reset/ResetPasswordScene'
import OffersScene from '../../scenes/order/offersList/OffersScene'
import OfferDetailScene from '../../scenes/order/offerDetails/OfferDetailsScene'
import OfferConfirmationScene from '../../scenes/offerConfirmation/OfferConfirmationScene'

Sentry.init({ dsn: 'https://e36fbf0d6ede44b2948d271ff26ec7e0@o270141.ingest.sentry.io/5374375' })

class App extends React.Component {
    constructor(props) {
        super(props)

        const enhancer = composeWithDevTools(
            applyMiddleware(
                thunk,
                middlewares.AuthApiMiddleware,
                middlewares.ClientApiMiddleware,
                middlewares.OrderApiMiddleware,
                middlewares.DestinationMiddleware,
                middlewares.AirportApiMiddleware,
                middlewares.FlightSearchApiMiddleware,
                middlewares.QuoteApiMiddleware
            )
        )
        this.store = createStore(rootReducer, enhancer)
    }

    componentDidMount() {
        initFonts()
    }

    render() {
        return (
            <Provider store={this.store}>
                <ToastContainer
                    position={'top-right'}
                    autoClose={4000}
                    hideProgressBar
                    pauseOnFocusLoss={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                />
                <Router>
                    <Switch>
                        <PrivateRoute exact path={'/'} component={LoginScene} history={history} />
                        <Route path={'/auth'} component={AuthScene} />
                        <Route path={'/reset-password'} component={ResetPasswordScene} />
                        <PrivateRoute path={'/dashboard'} component={DashboardScene} />
                        <PrivateRoute path={'/orders'} component={OrderScene} />
                        <PrivateRoute path={'/order/:id'} component={OrderScene} />
                        <PrivateRoute path={'/offer/:id'} component={OfferDetailScene} />
                        <Route path={'/flight-quote/:id'} component={OfferConfirmationScene} />
                        <PrivateRoute path={'/offers'} component={OffersScene} />
                        <PrivateRoute path={'/destinations'} component={DestinationsScene} />
                        <PrivateRoute path={'/clients'} component={ClientRoute} />
                        <PrivateRoute path={'/marketing-tool'} component={MarketingScene} />
                        <PrivateRoute path={'/faa'} component={FAAScene} />
                        <PrivateRoute path={'/weather-map'} component={WeatherScene} />
                        <PrivateRoute path={'/client/:id'} component={ClientScene} />
                        <PrivateRoute path={'/profile'} component={ProfileScene} />
                    </Switch>
                </Router>
            </Provider>
        )
    }
}

export default App
