import {
    ADD_NEW_LEG,
    API_FLY_EMPTY_INSTANT_QUOTE,
    API_LOAD_DESTINATIONS_REQUEST,
    AVIND_AIRPORT_REQUEST,
    CLEAR_FLIGHT_SEARCH,
    FLIGHT_BOOK_REQUEST,
    UPDATE_DESTINATIONS_FILTER_TYPE,
    UPDATE_ORDER_STATUS
} from './types'

export const loadDestinations = (search = {}) => (dispatch) => {
    dispatch({
        type: API_LOAD_DESTINATIONS_REQUEST,
        payload: { search }
    })

    return {
        type: API_LOAD_DESTINATIONS_REQUEST,
        payload: { search }
    }
}

export const searchDestinations = (search = {}) => (dispatch) => {
    dispatch({
        type: API_LOAD_DESTINATIONS_REQUEST,
        payload: { search }
    })

    return {
        type: API_LOAD_DESTINATIONS_REQUEST,
        payload: { search }
    }
}

export const updateFlightsFilter = (filter) => {
    return {
        type: UPDATE_DESTINATIONS_FILTER_TYPE,
        payload: filter
    }
}

export const searchForAirport = (city, type, index = null) => ({
    type: AVIND_AIRPORT_REQUEST,
    payload: { city, type, index }
})

export const searchFlights = (filters, numberOfSeats = 1, activeFilter) => ({
    type: API_FLY_EMPTY_INSTANT_QUOTE,
    payload: { filters, numberOfSeats, activeFilter }
})

export const bookFlight = (flight) => ({
    type: FLIGHT_BOOK_REQUEST,
    payload: flight
})

export const updateBookingStatus = (showOrderSuccess) => ({
    type: UPDATE_ORDER_STATUS,
    payload: showOrderSuccess
})

export const onAddLeg = () => ({
    type: ADD_NEW_LEG
})

export const resetFlights = () => ({
    type: CLEAR_FLIGHT_SEARCH
})
