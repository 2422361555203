import React from 'react'
import { logout } from '../../../utilities/authUtility'

class LogoutScene extends React.Component {
    componentDidMount() {
        const { history } = this.props
        logout(history)
        setTimeout(() => {
            return history.push('/auth/login')
        }, 500)
    }

    render() {
        return <p>{'Logging you out...'}</p>
    }
}

export default LogoutScene
